import { format, formatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const DEFAULT_DATE_FORMAT_PATTERN = 'MMM d, yyyy, hh:mm a';

export const formatDate = (date?: string, pattern = DEFAULT_DATE_FORMAT_PATTERN) => {
  if (!date) return;

  return format(new Date(date), pattern);
};

export const formatDateDiff = (date?: string) => {
  const fromDate = new Date(date || '');

  return date && formatDistance(fromDate, utcToZonedTime(new Date(), 'UTC'), { addSuffix: true });
};

export const formatCardNumber = (last4: string = '') => `******${last4}`;

export const convertFromCents = (value: number) => value / 100;

export const convertToCents = (value: number) => value * 100;

export const formatFieldToLabel = (value: string) => value.replace(/([A-Z]+)/g, ' $1');
