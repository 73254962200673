import config from '@vgs-config';
import { AuthService } from '@vgs/auth';
import { HttpClient } from '@/services';
import { mapToCamelCase, mapToSnakeCase } from './mappers';

export interface UserProfile {
  createdAt: string;
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  otpEnabled: boolean;
}

class IamApi {
  static BASE_URL = config.api.iamUrl;

  private _config = {
    baseURL: IamApi.BASE_URL,
  };

  get usersPath() {
    return '/users';
  }

  get sessionsPath() {
    return '/sessions';
  }

  getUserById(userId: string) {
    const url = `${this.usersPath}/${userId}`;

    return HttpClient.get<UserProfile>(url, this._config).then((data) => mapToCamelCase(data));
  }

  updateUserProfile(userId: string, data: Partial<UserProfile>) {
    const url = `${this.usersPath}/${userId}`;
    const payload = mapToSnakeCase(data);

    return HttpClient.patch<UserProfile>(url, payload, this._config).then((data) =>
      mapToCamelCase(data),
    );
  }

  logoutAll() {
    const url = `${this.sessionsPath}`;

    return HttpClient.delete(url, this._config).then(() => AuthService.logout());
  }
}

export default new IamApi();
