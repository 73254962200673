import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { AuthService } from '@vgs/auth';
import { sendErrorToRollbar } from '@/utils/rollbar';

const handleRequest = async (config: AxiosRequestConfig) => {
  await AuthService.updateToken();

  const accessToken = AuthService.getAccessToken();

  config.headers['Authorization'] = `Bearer ${accessToken}`;

  return config;
};

const handleResponse = (response: AxiosResponse) => {
  const { data } = response;

  return data;
};

const handleError = (error: any) => {
  sendErrorToRollbar(error);

  return Promise.reject(error);
};

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
});

httpClient.interceptors.request.use(handleRequest, handleError);
httpClient.interceptors.response.use(handleResponse, handleError);

export default httpClient;
