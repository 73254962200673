import { isNil } from 'lodash';

const CENTS_IN_A_DOLLAR = 100;
const EMPTY_VALUE = 0;

const convertToDollars = (value: number) => {
  if (value > EMPTY_VALUE) return value / CENTS_IN_A_DOLLAR;
  return EMPTY_VALUE;
};

export const transformReportingPayload = ({ date, approved, declined, pending }: any) => {
  return {
    date: new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    }),
    totalSubmitted: convertToDollars(pending + approved + declined),
    pending: convertToDollars(pending),
    approved: convertToDollars(approved),
    declined: convertToDollars(declined),
  };
};

const emptyTotalData = {
  totalSubmitted: EMPTY_VALUE,
  totalVolume: EMPTY_VALUE,
  totalApproved: EMPTY_VALUE,
  totalDeclined: EMPTY_VALUE,
  totalPending: EMPTY_VALUE,
};

export const getTotalData = (usageStatPayload?: any) => {
  if (!isNil(usageStatPayload?.metrics)) {
    const { totalSubmitted, totalVolume, totalApproved, totalDeclined, totalPending } =
      usageStatPayload;
    return {
      totalSubmitted,
      totalVolume: convertToDollars(totalVolume),
      totalApproved: convertToDollars(totalApproved),
      totalDeclined: convertToDollars(totalDeclined),
      totalPending: convertToDollars(totalPending),
    };
  }
  return { ...emptyTotalData };
};
