import React, { useMemo } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { useGuard } from '@vgs/hooks';
import { Loader } from '@/components';
import { useCurrentVault } from '@/hooks';

const GuardedRoute = (props: any) => {
  const { canActivate, routes, redirectTo, ...routeConfig } = props;
  const { vault } = useCurrentVault();
  const guardOptions = useMemo(
    () => ({
      tenantId: vault?.identifier,
    }),
    [vault?.identifier],
  );

  const { canActivate: shouldRender, pending } = useGuard(canActivate, guardOptions);

  if (pending) {
    return <Loader />;
  }

  return shouldRender ? (
    <Route
      {...routeConfig}
      render={(props) => {
        return (
          <Switch>
            {routes.map((route: RouteProps) => (
              <Route key={route.path as string} {...route} />
            ))}
            {redirectTo && <Redirect to={redirectTo} />}
          </Switch>
        );
      }}
    />
  ) : null;
};

export default GuardedRoute;
