import { useMutation, useQuery } from 'react-query';
import { PersonalizationApi } from '@/api';

export const usePersonalizationApi = (userId: string, options?: any) => {
  const userProfile = useQuery(
    ['profiles', userId],
    () => PersonalizationApi.getUserProfile(userId),
    options,
  );

  const updateUserProfile = useMutation(
    (data: { userId: string; settings: { [key: string]: string } }) => {
      const { userId, ...payload } = data;

      return PersonalizationApi.updateUserProfile(userId, payload);
    },
  );

  return {
    userProfile,
    updateUserProfile,
  };
};
