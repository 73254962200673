import { isNil } from 'lodash';
import { AccountsApi, MultiplexingApi } from '@/api';
import { PATHS } from './constants';
import queryClient, { QueryType } from './query-client';
import { default as browserHistory } from './browser-history';

export const setupCompleteGuard = async () => {
  const vaults = queryClient.getQueryState<any[]>(QueryType.VAULTS)?.data;

  let isSetupCompleted = !!vaults?.length;

  if (isNil(vaults)) {
    const vaults = await queryClient.fetchQuery(QueryType.VAULTS, AccountsApi.getVaults);

    isSetupCompleted = !!vaults.length;
  }

  return isSetupCompleted;
};

export const multiplexingGuard = async ({ tenantId }: { tenantId: string }) => {
  if (!tenantId) {
    return false;
  }

  let status = queryClient.getQueryState<{ isProvisioned: boolean; isForbidden: boolean }>([
    QueryType.VAULT_STATUS,
    tenantId,
  ])?.data;

  if (!status) {
    status = await queryClient.fetchQuery([QueryType.VAULT_STATUS, tenantId], () =>
      MultiplexingApi.getInstanceStatus(tenantId),
    );
  }

  const hasPermissions = status?.isProvisioned && !status?.isForbidden;

  if (!hasPermissions) {
    setTimeout(() => browserHistory.push(PATHS.GETTING_STARTED), 0);
  }

  return hasPermissions || false;
};
