import { useQuery } from 'react-query';
import { AccountsApi, Organization, Vault } from '@/api';

const QUERY_STALE_TIME = 3 * 1000;

export const useAccountsApi = (options?: any) => {
  const organizations = useQuery<Organization[]>('organizations', AccountsApi.getOrganizations, {
    staleTime: QUERY_STALE_TIME,
    ...options,
  });

  const vaults = useQuery<Vault[]>('vaults', AccountsApi.getVaults, {
    staleTime: QUERY_STALE_TIME,
    ...options,
  });

  return {
    organizations,
    vaults,
  };
};
