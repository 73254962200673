import { rest } from 'msw';

import response from '../data/gateways.json';

const gatewaysListResolver = (req, res, ctx) => {
  return res(ctx.json(response));
};

const gatewaysUrlRegexp = /https:\/\/payments.(dev|sandbox|live).verygoodsecurity.app\/gateways/;

export const fetchGatewaysList = rest.get(gatewaysUrlRegexp, gatewaysListResolver);
