import { rest } from 'msw';
import config from '@vgs-config';
import response from '../data/usage-reporting.json';

const usageReportingResolver = (req, res, ctx) => {
  return res(ctx.json(response));
};

//wip decision
// TODO move vars to env config
const usageStatsDevUrl = /.*njord\.verygood?.*\/payopt-stats\/payments/;
const usageStatsProdUrl = /.*njord\.apps\.verygood\.systems\/payopt-stats\/payments/;

const currentUsageStatsUrl = config.env === 'development' ? usageStatsDevUrl : usageStatsProdUrl;
export const fetchUsageReporting = rest.get(currentUsageStatsUrl, usageReportingResolver);
