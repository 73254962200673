import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { Popover, Menu, Empty } from '@vgs/ui-core';

import { useCurrentVault } from '@/hooks';
import { Vault } from '@/api';
import { SearchInput } from '@/components';

import { VaultIcon } from '@vgs/ui-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IVaultEnvIdentifier } from '@vgs/ui-core/lib/vault-icon';

const VaultSwitcher = () => {
  const { vault: currentVault, linkedVaults: vaults, switchVault } = useCurrentVault();
  const [isSwitcherShown, setIsSwitcherShown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>();

  const switchCurrentVault = useCallback(
    (vault: Vault) => () => {
      switchVault(vault);
      setIsSwitcherShown(false);
    },
    [switchVault],
  );

  const toggleSwitcher = useCallback(() => {
    setIsSwitcherShown((prevState) => !prevState);
  }, []);

  const handleSearch = useCallback((value) => setQuery(value), []);

  const filteredVaults = useMemo(() => {
    if (!query) return vaults;

    return vaults?.filter(({ identifier, name }) => {
      return `${identifier.toLowerCase()}${name.toLowerCase()}`.includes(query.toLowerCase());
    });
  }, [vaults, query]);

  const content = (
    <>
      <div className="tw-flex tw-flex-col tw-py-4 tw-px-6 tw-border-b tw-border-neutral-200">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
          <h2 className="tw-subhead">Your Vaults</h2>
        </div>

        <SearchInput onSearch={handleSearch} />
      </div>

      {!filteredVaults?.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

      <Menu className="tw-overflow-auto">
        {filteredVaults.map((vault: Vault) => {
          const { id, identifier, name } = vault;
          const isDisabled = id === currentVault?.id;

          return (
            <Menu.Item
              key={id}
              className={cx('vgs-vault-row', { disabled: isDisabled })}
              onClick={switchCurrentVault(vault)}
            >
              <div className="tw-flex tw-flex-col">
                <h2 className="vgs-vault-label tw-subhead tw-truncate">{name}</h2>
                <span className="tw-text-body-b2 tw-truncate">{identifier}</span>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );

  if (!currentVault) {
    return null;
  }

  return (
    <Popover
      overlayClassName="vgs-vault-switcher"
      trigger="click"
      placement="bottomLeft"
      autoAdjustOverflow={false}
      content={content}
      visible={isSwitcherShown}
      onVisibleChange={toggleSwitcher}
    >
      <div className="tw-flex vgs-switcher-item tw-ml-6 tw-items-center tw-text-body-b2 tw-cursor-pointer">
        <div className="tw-truncate tw-text-white tw-grid">
          <span className="tw-text-neutral-400 tw--mb-1 tw-text-xs  organization-switcher-title">
            Vault
          </span>
          {currentVault.name} - {currentVault.environment}
        </div>
        <span className="vault-list__badge tw-pl-0 tw-pt-3">
          <VaultIcon
            type={currentVault?.environment as IVaultEnvIdentifier}
            className="tw-ml-2 tw-text-white testie"
          />
        </span>
        <div className={'vgs-switcher-icon tw-text-sm'}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
    </Popover>
  );
};

export default VaultSwitcher;
