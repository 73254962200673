import { camelize, camelizeKeys, decamelize, decamelizeKeys } from 'humps';
import { isArray, isNil, isObject, isString, map, reduce } from 'lodash';
import {
  ArrayOfObjects,
  CamelToSnakeCase,
  ArrayCamelToSnakeCase,
  ObjectCamelToSnakeCase,
  ObjectSnakeToCamelCase,
  ArraySnakeToCamelCase,
  SnakeToCamelCase,
  StringCaseType,
  ArrayCaseType,
  ObjectCaseType,
} from '@/api/mappers-types';
import { isObjectType } from '@/utils/ts-guards';

export enum TransformCase {
  KEYS,
  ENTRIES,
  ALL,
}

export enum Cases {
  TO_SNAKE = 'TO_SNAKE',
  TO_CAMEL = 'TO_CAMEL',
}

const applyKeyTransform = (key: string, transform: Function) =>
  isString(key) ? transform(key) : key;

function applyTransform<T extends string, CASE extends Cases>(
  payload: T,
  type: TransformCase,
  mapper: Function,
): StringCaseType<T, CASE>;
function applyTransform<T extends ArrayOfObjects, CASE extends Cases>(
  payload: T,
  type: TransformCase,
  mapper: Function,
): ArrayCaseType<T, CASE>;
function applyTransform<T extends object, CASE extends Cases>(
  payload: T,
  type: TransformCase,
  mapper: Function,
): ObjectCaseType<T, CASE>;
function applyTransform<T, CASE>(payload: T, type: TransformCase, mapper: Function): T;
function applyTransform(payload: unknown, type: TransformCase, mapper: Function): unknown {
  const shouldTransformKey = [TransformCase.KEYS, TransformCase.ALL].includes(type);

  if (isString(payload)) {
    return mapper(payload);
  }

  if (isObjectType(payload)) {
    return reduce(
      payload,
      (result, value, key) => ({
        ...result,
        [shouldTransformKey ? applyKeyTransform(key, mapper) : key]: applyTransform(
          value,
          type,
          mapper,
        ),
      }),
      {},
    );
  }

  if (isArray(payload)) {
    return map(payload, (value) => applyTransform(value, type, mapper));
  }

  return payload;
}

export function mapToCamelCase<T extends string>(
  data: T,
  transform?: TransformCase,
): SnakeToCamelCase<T>;
export function mapToCamelCase<T extends ArrayOfObjects>(
  data: T,
  transform?: TransformCase,
): ArraySnakeToCamelCase<T>;
export function mapToCamelCase<T extends object>(
  data: T,
  transform?: TransformCase,
): ObjectSnakeToCamelCase<T>;
export function mapToCamelCase<T>(data: T, transform?: TransformCase): T;
export function mapToCamelCase<T>(data: T, transform?: TransformCase) {
  if (isNil(data)) return data;
  if (!transform) transform = TransformCase.KEYS;
  if (transform === TransformCase.KEYS && isObject(data)) {
    return camelizeKeys(data);
  }
  return applyTransform<T, Cases.TO_CAMEL>(data, transform, camelize);
}

export function mapToSnakeCase<T extends string>(
  data: T,
  transform?: TransformCase,
): CamelToSnakeCase<T>;
export function mapToSnakeCase<T extends ArrayOfObjects>(
  data: T,
  transform?: TransformCase,
): ArrayCamelToSnakeCase<T>;
export function mapToSnakeCase<T extends object>(
  data: T,
  transform?: TransformCase,
): ObjectCamelToSnakeCase<T>;
export function mapToSnakeCase<T>(data: T, transform?: TransformCase): T;
export function mapToSnakeCase<T>(data: T, transform?: TransformCase) {
  if (isNil(data)) return data;
  if (!transform) transform = TransformCase.KEYS;
  if (transform === TransformCase.KEYS && isObject(data)) {
    return decamelizeKeys(data);
  }
  return applyTransform<T, Cases.TO_SNAKE>(data, transform, decamelize);
}
