import { rest } from 'msw';

import response from '../data/personalization-api.json';

const personalizationApiResolvers = (req, res, ctx) => {
  return res(ctx.json(response));
};

const personalizationUrlRegex = /profiles\/c9b02ab1-8973-470e-8cda-f799ef8e2013.*/;

export const fetchPersonalizationData = rest.get(
  personalizationUrlRegex,
  personalizationApiResolvers,
);
