import { useInfiniteQuery } from 'react-query';
import { FinancialInstrument, MultiplexingApi, Response } from '@/api';

export const useFinancialInstrumentsApi = () => {
  const fetchFinancialInstruments = ({
    pageParam: page,
    queryKey,
  }: {
    pageParam?: number;
    queryKey: any;
  }) => {
    const [, { filter }] = queryKey;

    return MultiplexingApi.getFinancialInstrumentsList({ page, filter }) as Promise<
      Response<FinancialInstrument[]>
    >;
  };

  const useInfiniteQueryWithSearch = (filter?: {
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
    keys: string[];
  }) => {
    return useInfiniteQuery(['financial-instruments', { filter }], fetchFinancialInstruments, {
      getNextPageParam: ({ meta }: Response<FinancialInstrument[]>) => {
        const { nextPage } = meta || {};

        return nextPage;
      },
    });
  };

  return {
    financialInstrumentsList: useInfiniteQueryWithSearch,
  };
};
