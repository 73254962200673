export const DEFAULT_TIME_OPTION = { name: 'This month', value: 'this_month' };
export const DEFAULT_PERSON_OPTION = {
  name: 'Merchant Organization',
  value: 'merchant_organization',
};

export const DEFAULT_PAYMENT_REQUEST_OPTION = {
  name: 'All Payment Requests ',
  value: 'all_payment_requests',
};

export const DEFAULT_PAYMENT_METHOD_OPTION = {
  name: 'All Payment Methods ',
  value: 'all_payment_methods',
};

export const TIME_OPTIONS = [
  { name: 'This month', value: 'this_month' },
  // TODO Add chart adjustment for year chart
  // { name: 'This year', value: 'this_year' },
];

export const PERSON_OPTIONS = [
  { name: 'Merchant Organization', value: 'merchant_organization' },
  { name: 'TSub-Merchant Organization', value: 'sub_merchant_organization' },
];

export const PAYMENT_REQUEST_OPTIONS = [
  { name: 'All Payment Requests ', value: 'all_payment_request' },
  { name: '3DS Check', value: '3ds_check' },
  { name: 'AVS Check', value: 'avs_check' },
  { name: 'Attributes', value: 'attributes' },
];

export const PAYMENT_METHOD_OPTIONS = [
  { name: 'All Payment Methods ', value: 'all_payment_request' },
  { name: 'PANs', value: 'pans' },
  { name: 'Network Token', value: 'network_token' },
  { name: 'Apple Pay', value: 'apple_pay' },
  { name: 'Google Pay', value: 'google_pay' },
];

export const CARD_BRAND_OPTIONS = [
  { name: 'All Card Brands', value: 'all_card_brands' },
  { name: 'VISA', value: 'visa' },
  { name: 'Master Card ', value: 'master_card' },
];

export const initialFiltersState = {
  timeFilter: DEFAULT_TIME_OPTION,
  paymentRequestFilter: null,
  paymentMethodFilter: null,
  selectedPaymentMethodFilter: null,
  selectedPaymentRequestFilter: null,
};

export type PaymentOrchestrationFilters = typeof initialFiltersState;
