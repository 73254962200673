import React, { FC, useCallback, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip } from '@vgs/ui-core';
import { formatDate } from '@/utils';
import { useRulesApi } from '@/hooks';
import { DeletePrompt } from '@/components';
import { filterByDateRangeOnClient, DateRangePicker } from '@vgs/data-table';

const RuleTypeCell: FC<{ value: string }> = React.memo(({ value }) => {
  return <span className="tw-capitalize">{value}</span>;
});

const RuleDateCell: FC<{ value: string }> = React.memo((props) => {
  const { value } = props;

  return (
    <Tooltip title={value}>
      <span>{formatDate(value)}</span>
    </Tooltip>
  );
});

const RuleActionCell = React.memo((props) => {
  const {
    row: {
      original: { id },
    },
  } = props as any;
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const { deleteRule } = useRulesApi();

  const togglePrompt = useCallback(
    (isVisible = true) =>
      (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setShowDeletePrompt(isVisible);
      },
    [],
  );

  const handleOnDelete = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      deleteRule.mutate(id, {
        onSuccess: () => setShowDeletePrompt(false),
        onError: () => setShowDeletePrompt(false),
      });
    },
    [id, deleteRule],
  );

  return (
    <>
      <Button type="error" size="sm" className="tw-flex tw-align-center" onClick={togglePrompt()}>
        <CloseOutlined />
        Delete
      </Button>
      <DeletePrompt
        visible={showDeletePrompt}
        isLoading={deleteRule.isLoading}
        title="Confirm rule removal"
        onDelete={handleOnDelete}
        onCancel={togglePrompt(false)}
      >
        Are you sure delete this rule?
      </DeletePrompt>
    </>
  );
});

export const RULE_ACTIONS = {
  block: 'Block',
  retry: 'Retry',
  route: 'Route',
};

export const columns = [
  {
    Header: 'Order',
    accessor: 'ordinal',
    width: 75,
  },
  {
    Header: 'Id',
    accessor: 'id',
    minWidth: 230,
  },
  {
    Header: 'Kind',
    accessor: 'action',
    Cell: RuleTypeCell,
    width: 100,
  },
  {
    Header: 'Description',
    accessor: 'description',
    minWidth: 250,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: RuleDateCell,
    minWidth: 200,
    Filter: DateRangePicker,
    filter: filterByDateRangeOnClient,
  },
  {
    Header: 'Action',
    id: 'actions',
    Cell: RuleActionCell,
  },
];
