import React, { useCallback, useState, FC, useMemo } from 'react';
import cx from 'classnames';
import { Icon, Popover, Menu, Empty } from '@vgs/ui-core';

import { useAccountsApi, useCurrentVault } from '@/hooks';
import { Organization } from '@/api';
import { SearchInput } from '@/components';

import { Link } from 'react-router-dom';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OrganizationSwitcherProps {
  className?: string;
}

const OrganizationSwitcher: FC<OrganizationSwitcherProps> = (props) => {
  const { organizations } = useAccountsApi();
  const { organization: currentOrganization, switchOrganization } = useCurrentVault();

  const [isSwitcherShown, setIsSwitcherShown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>();

  const switchCurrentOrganization = useCallback(
    (organization: Organization) => () => {
      switchOrganization(organization);
      setIsSwitcherShown(false);
    },
    [switchOrganization],
  );

  const toggleSwitcher = useCallback(() => {
    setIsSwitcherShown((prevState) => !prevState);
  }, []);

  const handleSearch = useCallback((value) => setQuery(value), []);

  const filteredOrganizations = useMemo(() => {
    if (!query) return organizations.data;

    return organizations.data?.filter(({ id, name }) => {
      return `${id!.toLowerCase()}${name.toLowerCase()}`.includes(query.toLowerCase());
    });
  }, [organizations.data, query]);

  const content = (
    <>
      <div className="tw-flex tw-flex-col tw-py-4 tw-px-6 tw-border-b tw-border-neutral-200">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
          <h2 className="tw-subhead">Your Organizations</h2>
        </div>

        <SearchInput onSearch={handleSearch} />
      </div>

      {!filteredOrganizations?.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

      <Menu className="tw-overflow-auto">
        {filteredOrganizations?.map((organization) => {
          const { id, name } = organization;
          const isDisabled = id === currentOrganization?.id;

          return (
            <Menu.Item
              key={id}
              className={cx('vgs-organization-row', { disabled: isDisabled })}
              onClick={switchCurrentOrganization(organization)}
            >
              <div className="tw-flex tw-flex-col">
                <h2 className="vgs-organization-label tw-subhead tw-truncate">{name}</h2>
                <span className="tw-text-body-b2 tw-truncate">{id}</span>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );

  if (!currentOrganization) {
    return null;
  }

  return (
    <Popover
      overlayClassName="vgs-organization-switcher"
      trigger="click"
      placement="bottomLeft"
      autoAdjustOverflow={false}
      content={content}
      visible={isSwitcherShown}
      onVisibleChange={toggleSwitcher}
    >
      <div className="tw-flex tw-items-center">
        <Link to="/" className="navbar-brand">
          <img src="/images/vgs-logo-white.svg" className="logo" alt="Very Good Security" />
        </Link>
        <div className="tw-flex vgs-switcher-item tw-ml-4 tw-items-center tw-text-body-b2 tw-cursor-pointer">
          <div className="tw-truncate tw-text-white tw-grid">
            <span className="tw-text-neutral-400 tw--mb-1 tw-text-xs organization-switcher-title">
              Organization
            </span>
            <span>{currentOrganization.name}</span>
          </div>
          <div className={'vgs-switcher-icon tw-text-sm'}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default OrganizationSwitcher;
