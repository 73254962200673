import { rest } from 'msw';

import response from '../data/gateway-options.json';

const gatewayOptionsListResolver = (req, res, ctx) => {
  return res(ctx.json(response));
};

const gatewayOptionsUrlRegexp =
  /https:\/\/tnt.*(dev|sandbox|live).verygoodproxy.com\/gateway_options/;

export const fetchGatewayOptionsList = rest.get(
  gatewayOptionsUrlRegexp,
  gatewayOptionsListResolver,
);
