export const currConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'vgs-organization': '',
  },
};

export const downloadConfig = {
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'text/csv',
    'vgs-organization': '',
    responseType: 'blob',
  },
};
