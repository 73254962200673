import React, { FC } from 'react';
import { intersection, get } from 'lodash';
import { formatDate, formatCardNumber } from '@/utils';
import { Tooltip } from '@vgs/ui-core';
import { DateRangePicker } from '@vgs/data-table';

const FINANCIAL_INSTRUMENTS_TYPE = {
  card: 'Card',
  pspToken: 'PSP Token',
};

export const getFirstName = (fullName: string = '') => {
  const [firstname] = fullName.split(' ');

  return firstname;
};

export const getLastName = (fullName: string = '') => {
  const [, ...lastName] = fullName.split(' ');

  return lastName;
};

export const getType = (row: any) => {
  const [type] = intersection(Object.keys(FINANCIAL_INSTRUMENTS_TYPE), Object.keys(row || {}));

  return get(FINANCIAL_INSTRUMENTS_TYPE, type);
};

const FinancialInstrumentDateCell: FC<{ value: string }> = React.memo((props) => {
  const { value } = props;

  return (
    <Tooltip title={value}>
      <span>{formatDate(value)}</span>
    </Tooltip>
  );
});

export const columns = [
  {
    Header: 'Id',
    accessor: 'id',
    minWidth: 250,
  },
  {
    Header: 'Number',
    id: 'cardNumber',
    accessor: (row: any) => row.card.last4,
    Cell: ({ value }: { value: string }) => {
      return <span className="tw-text-primary">{formatCardNumber(value)}</span>;
    },
    width: 100,
    disableFilters: true,
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: (row: any) => getType(row),
    width: 75,
  },
  {
    Header: 'Card Name',
    id: 'cardName',
    accessor: (row: any) => row.card.name,
  },
  {
    Header: 'Brand',
    id: 'cardBrand',
    accessor: (row: any) => row.card.brand,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: FinancialInstrumentDateCell,
    minWidth: 200,
    Filter: DateRangePicker,
  },
];
