import gatewayOptions from '../mocks/data/gateway-options.json';

import { get, reduce } from 'lodash';
import { sendErrorToRollbar } from '@/utils/rollbar';

import { HttpClient } from '@/services';
import { mapToCamelCase, mapToSnakeCase, TransformCase } from './mappers';
import { Pagination, Response } from './response';

export type Gateway = {
  id: string;
  defaultCurrency: string;
  defaultGateway: boolean;
  type: string;
  config: any;
};

export interface Rule {
  id: string;
  description: string;
  criteria: string;
  action: string;
  routeToGateway: string;
  ordinal: number;
}

export interface FinancialInstrument {
  id: string;
  card: {
    name: string;
    number: string;
    expMonth: number;
    expYear: number;
    cvc: number | null;
    type: string;
    countryOfOrigin: string;
    brand: string;
    last4: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface GatewayResponse {
  errorCode: string;
  id: string;
  message: string;
  rawResponse: any;
  state: string;
}

export interface Transfer {
  amount: number;
  amountReversed: number;
  currency: string;
  destination: string;
  fee: number;
  card: {
    name: string;
    number: string;
    expMonth: number;
    expYear: number;
    cvc: number | null;
    type: string;
    countryOfOrigin: string;
    brand: string;
  };
  binAttributes: {
    productType: string;
    issuerName: string;
    countryOfOrigin: string;
  };
  gateway: Gateway;
  gatewayResponse: GatewayResponse;
  id: string;
  source: string;
  state: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface filterItem {
  id: string;
  value: string | Array<string>;
}

const ENVIRONMENT_PLACEHOLDER = '{{ENVIRONMENT}}';
const TENANT_ID_PLACEHOLDER = '{{TENANT}}';
const ORCHESTRATION_TENANT_HEADER = 'orchestration-tenant';
const CREATED_AT_FIELD = 'createdAt';
const DOMAIN_SUFFIX = '{{DOMAIN_SUFFIX}}';
const DEV_DOMAIN_SUFFIX = 'io';
const PROD_DOMAIN_SUFFIX = 'com';

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

const applyFilters = (filters: filterItem[]) => {
  const dateRangeFilters = filters.filter((item: filterItem) => item.id === CREATED_AT_FIELD);
  const otherFilters = filters.filter((item: filterItem) => item.id !== CREATED_AT_FIELD);
  const [{ value }] = dateRangeFilters;
  const [startDate, endDate] = value;
  const updatedFilters = [
    { id: 'createdAt', value: startDate },
    { id: 'createdAt', value: endDate },
    ...otherFilters,
  ];
  return updatedFilters;
};

const DEFAULT_SORT = [{ field: 'createdAt', direction: SortDirection.DESC }];

const buildQueryParams = (params: any) =>
  reduce(
    params,
    (query, value, key) => {
      if (!value) return query;

      const encoded = encodeURIComponent(typeof value === 'object' ? JSON.stringify(value) : value);

      return `${query ? query + '&' : '?'}${key}=${encoded}`;
    },
    '',
  );

const withPagination = <T>(data: T, meta?: Pagination, page?: number) => {
  const currentPage = page ?? 0;
  const nextPage = currentPage < (meta?.totalPages ?? 1) - 1 && currentPage + 1;

  return { data, meta: { ...meta, nextPage } };
};

enum SearchOperator {
  EQUALS = '==',
  NOT_EQUALS = '!=',
  GREATER = '>',
  GREATER_EQUALS = '>=',
  LESSER = '<',
  LESSER_EQUALS = '<=',
  LIKE = 'like',
  ILIKE = 'ilike',
  IN = 'in',
  NOT_IN = 'not_in',
  AND = 'and',
  OR = 'or',
}

export const buildSearchParams = (params?: {
  globalFilter: string;
  filters: Array<{ id: string; value: string }>;
  keys: string[];
}) => {
  if (!params?.globalFilter && !params?.filters?.length) return null;

  const { globalFilter, filters, keys } = params;

  const rules: { [key in `${SearchOperator}`]?: (value: string) => string } & {
    default: (value: string) => string;
  } = {
    [SearchOperator.ILIKE]: (value: string) => `%${value.trim()}%`,
    default: (value: string) => value.trim(),
  };

  const composeRule = (field: string, value: string, op = SearchOperator.ILIKE) => {
    return {
      op: field === 'type' ? SearchOperator.EQUALS : op,
      field: mapToSnakeCase(field, TransformCase.ENTRIES),
      value: (rules[op] || rules['default'])(value),
    };
  };

  let conditions = [];

  if (globalFilter) {
    const values = keys.reduce(
      (prev: any, field: string) => [...prev, composeRule(field, globalFilter)],
      [],
    );
    const rule = { op: SearchOperator.OR, values };

    conditions.push(rule);
  }

  const composeDateRangeFields = (field: string, value: string) => {
    const [startDate] = filters;
    const isStartDate = startDate.value === value;
    return isStartDate
      ? composeRule(field, value, SearchOperator.GREATER_EQUALS)
      : composeRule(field, value, SearchOperator.LESSER_EQUALS);
  };

  filters.forEach(({ id: field, value }: { id: string; value: string }) => {
    const rule =
      field === 'createdAt' ? composeDateRangeFields(field, value) : composeRule(field, value);

    conditions.push(rule);
  });
  return conditions;
};

class MultiplexingApi {
  static BASE_URL = `https://${TENANT_ID_PLACEHOLDER}-4880868f-d88b-4333-ab70-d9deecdbffc4.${ENVIRONMENT_PLACEHOLDER}.verygoodproxy.${DOMAIN_SUFFIX}`;
  private _config: {
    baseURL: string;
    headers: { [key: string]: string };
  } = {
    baseURL: MultiplexingApi.BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  constructor() {
    this.getFinancialInstrumentsList = this.getFinancialInstrumentsList.bind(this);
    this.getGatewaysList = this.getGatewaysList.bind(this);
    this.getGatewayOptionsList = this.getGatewayOptionsList.bind(this);
    this.createGateway = this.createGateway.bind(this);
    this.deleteGateway = this.deleteGateway.bind(this);
    this.getRulesList = this.getRulesList.bind(this);
    this.createRule = this.createRule.bind(this);
    this.updateRule = this.updateRule.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
    this.getTransfersList = this.getTransfersList.bind(this);
    this.getInstanceStatus = this.getInstanceStatus.bind(this);
    this.updateGateway = this.updateGateway.bind(this);
  }

  get hostUrl() {
    return this._config.baseURL;
  }

  get financialInstrumentsPath() {
    return '/financial_instruments';
  }

  get gatewaysPath() {
    return '/gateways';
  }

  get gatewayOptionsPath() {
    return '/gateway_options';
  }

  get rulesPath() {
    return '/rules';
  }

  get transfersPath() {
    return '/transfers';
  }

  get statusPath() {
    return '/tenants';
  }

  setBaseUrl(id: string, env: string) {
    const isDev = (process.env.NODE_CONFIG_ENV ?? process.env.NODE_ENV) === 'development';
    const vaultEnv = env.toLocaleLowerCase();
    const currentDomainSuffix = isDev ? DEV_DOMAIN_SUFFIX : PROD_DOMAIN_SUFFIX;

    this._config.baseURL = MultiplexingApi.BASE_URL.replace(ENVIRONMENT_PLACEHOLDER, vaultEnv)
      .replace(TENANT_ID_PLACEHOLDER, id)
      .replace(DOMAIN_SUFFIX, currentDomainSuffix);
    this._config.headers = { ...this._config.headers, [ORCHESTRATION_TENANT_HEADER]: id };
  }

  getInstanceStatus(id: string): Promise<{ isProvisioned: boolean; isForbidden?: boolean }> {
    const url = `${this.statusPath}/${id}`;

    return HttpClient.get(url, this._config)
      .then(() => {
        return {
          isProvisioned: true,
        };
      })
      .catch((err) => {
        const { response } = err;

        if (!response) {
          return {
            isProvisioned: false,
          };
        }

        const { status } = response;

        return {
          isProvisioned: status === 200,
          isForbidden: status === 403,
        };
      });
  }
  getFinancialInstrumentsList(params: any) {
    const { filter } = params;
    const { filters, globalFilter, keys } = filter;
    let paramFilters = params?.filter;
    const isDateFilterInParams = params?.filter.filters.some(
      (filterItem: any) => filterItem.id === CREATED_AT_FIELD,
    );

    if (isDateFilterInParams) {
      const updatedFilters = applyFilters(filters);
      paramFilters = { globalFilter, keys, filters: updatedFilters };
    }

    const searchParams = buildSearchParams(paramFilters);

    const queryParams = buildQueryParams({
      ...params,
      filter: searchParams,
      sort: mapToSnakeCase(DEFAULT_SORT, TransformCase.ENTRIES),
    });
    const url = `${this.financialInstrumentsPath}${queryParams}`;

    return HttpClient.get<Response<FinancialInstrument[]>, Response<FinancialInstrument[]>>(
      url,
      this._config,
    )
      .then(({ data, meta }) => {
        return withPagination(mapToCamelCase(data), mapToCamelCase(meta), params?.page);
      })
      .catch((err) => {
        console.error(err);
        sendErrorToRollbar(err);

        return { data: [] };
      });
  }

  getGatewaysList(params?: { page?: number; limit?: number; filter?: any }) {
    const searchParams = buildSearchParams(params?.filter);
    const queryParams = buildQueryParams({ ...params, filter: searchParams });
    const url = `${this.gatewaysPath}${queryParams}`;

    return HttpClient.get<Response<Gateway[]>, Response<Gateway[]>>(url, this._config)
      .then(({ data, meta }) => {
        const normalized = data.map((item) => ({ ...item, type: mapToCamelCase(item.type) }));

        return withPagination(mapToCamelCase(normalized), mapToCamelCase(meta), params?.page);
      })
      .catch((err) => {
        console.error(err);
        sendErrorToRollbar(err);
      });
  }

  async getGatewayOptionsList() {
    const url = `${this.gatewayOptionsPath}`;
    try {
      const { data } = await HttpClient.get(url, this._config);
      if (data) return mapToCamelCase(data);
    } catch (err) {
      console.error('failed to get gateway options \n', err);
      return gatewayOptions.data;
    }
  }

  createGateway(data: Gateway) {
    const url = `${this.gatewaysPath}`;
    const payload = mapToSnakeCase({ ...data, type: mapToSnakeCase(data.type) });

    return HttpClient.post<Gateway>(url, payload, { ...this._config }).then(({ data }) =>
      mapToCamelCase({ ...data, type: mapToCamelCase(data.type) }),
    );
  }

  deleteGateway(id: string) {
    const url = `${this.gatewaysPath}/${id}`;

    return HttpClient.delete(url, { ...this._config }).catch((err) => {
      const {
        response: { data },
      } = err;

      throw Error(get(data, 'errors[0].detail', 'Failed to delete gateway'));
    });
  }

  updateGateway(data: Gateway) {
    const { id, ...payloadFields } = data;
    const payload = mapToSnakeCase({ ...payloadFields, type: mapToSnakeCase(data.type) });

    const url = `${this.gatewaysPath}/${id}`;

    return HttpClient.put(url, payload, { ...this._config })
      .then(({ data }) => mapToCamelCase({ ...data, type: mapToCamelCase(data.type) }))
      .catch((err) => {
        const {
          response: { data },
        } = err;

        throw Error(get(data, 'errors[0].detail', 'Failed to update gateway'));
      });
  }

  getRulesList(params?: { page?: number; limit?: number; filter?: any }) {
    const searchParams = buildSearchParams(params?.filter);
    const queryParams = buildQueryParams({ ...params, filter: searchParams });
    const url = `${this.rulesPath}${queryParams}`;

    return HttpClient.get<Response<Rule[]>, Response<Rule[]>>(url, this._config)
      .then(({ data, meta }) => {
        return withPagination(mapToCamelCase(data), mapToCamelCase(meta), params?.page);
      })
      .catch((err) => {
        console.error(err);
        sendErrorToRollbar(err);

        return { data: [] };
      });
  }

  createRule(data: Rule) {
    const url = `${this.rulesPath}`;
    const payload = mapToSnakeCase(data);

    return HttpClient.post<typeof payload>(url, payload, { ...this._config }).then(({ data }) =>
      mapToCamelCase(data),
    );
  }

  updateRule(data: Rule) {
    const { id, ...payload } = mapToSnakeCase(data);
    const url = `${this.rulesPath}/${id}`;

    return HttpClient.put(url, payload, { ...this._config })
      .then(({ data }) => mapToCamelCase({ ...data, type: mapToCamelCase(data.type) }))
      .catch((err) => {
        const {
          response: { data },
        } = err;

        throw Error(get(data, 'errors[0].detail', 'Failed to update rules'));
      });
  }

  deleteRule(id: string) {
    const url = `${this.rulesPath}/${id}`;

    return HttpClient.delete(url, { ...this._config }).catch((err) => {
      const {
        response: { data },
      } = err;

      throw Error(get(data, 'errors[0].detail', 'Failed to delete rule'));
    });
  }

  getTransfersList(params?: { page?: number; limit?: number; filter?: any; sort?: any }) {
    const searchParams = buildSearchParams(params?.filter);
    const queryParams = buildQueryParams({
      ...params,
      filter: searchParams,
    });
    const url = `${this.transfersPath}${queryParams}`;

    return HttpClient.get<Response<Transfer[]>, Response<Transfer[]>>(url, this._config)
      .then(({ data, meta }) => {
        const normalized = mapToCamelCase(data).map((transfer) => {
          transfer.gatewayResponse.rawResponse = JSON.parse(transfer.gatewayResponse.rawResponse);

          return transfer;
        });

        return withPagination<Transfer[]>(normalized, mapToCamelCase(meta), params?.page);
      })
      .catch((err) => {
        console.error(err);
        sendErrorToRollbar(err);

        return { data: [] };
      });
  }
}

export default new MultiplexingApi();
