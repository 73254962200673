import ComponentsDataBoard from '@vgs/data-board';
import DataChart from '@vgs/data-chart';
import Filter from './components/Filter/Filter';
import './components-data-board.scss';
import './components/Filter/filters.scss';
import './components-data-chart.scss';
import {
  PayOrchestrationUsageReports,
  UsageMetricsData,
  useBilling,
  useCurrentVault,
  useUsageReporting,
} from '@/hooks';
import { useContext } from 'react';
import { getTotalData } from './utils';
import { ReportingHeader } from '@vgs/reporting-header';
import { Button, Icon } from '@vgs/ui-core';
import { FiltersContext } from './components/Filter/filter-context';
import { useVgsStaffFlag } from '@/hooks/useVgsStaffFlag';
import useTransformedChartData from '@/hooks/useTransformedChartData';
import { getBillingModel } from '@/hooks/useBillingApi';

const REPORTING_HEADER_TITLE = 'Usage Dashboard';

const getTabsContent = (
  usageStatData?: PayOrchestrationUsageReports,
  transformedData?: UsageMetricsData[],
) => {
  const totalData = getTotalData(usageStatData);
  const totalSecondTabData = getTotalData();
  const updatedData = { ...totalData, metrics: transformedData };

  const chartWithTestData = <DataChart data={updatedData} />;
  const emptyChart = <DataChart data={totalSecondTabData} />;
  return [chartWithTestData, emptyChart];
};

export default function UsageReports() {
  const usageStatsFilters = useContext(FiltersContext);

  const { organization: currentOrganization, vault } = useCurrentVault();

  const response = useBilling(currentOrganization?.id);
  const { data: billingPayload } = response;

  const billingModel = getBillingModel(billingPayload);

  const { usageStats, useCsvReports } = useUsageReporting(
    {
      internalId: currentOrganization?.internalId,
      id: currentOrganization?.id,
      tenantId: vault?.identifier,
    },
    usageStatsFilters,
  );

  const { refetch: downloadCsvFile } = useCsvReports;
  const { data: usageStatData, refetch: pullUsageStats } = usageStats;

  const { transformedChartData: transformedData } = useTransformedChartData(usageStatData);
  const isVGSUser = useVgsStaffFlag();
  const tabComponents = getTabsContent(usageStatData, transformedData);
  const totalData = getTotalData(usageStatData);

  const handleDownload = () => {
    downloadCsvFile();
  };
  // TODO handle state during the loading
  // of required data

  return (
    <>
      <ReportingHeader
        pricingModel={billingModel}
        organizationState={currentOrganization!.state}
        // @ts-ignore
        isVGSUser={isVGSUser}
        title={REPORTING_HEADER_TITLE}
      />
      <div className="tw-flex tw-items-center tw-justify-between">
        <Filter pullUsageStats={pullUsageStats} />
        <Button type="ghost_gray" className="download-btn" onClick={() => handleDownload()}>
          <Icon type={'download'} />
          <span>Download CSV</span>
        </Button>
      </div>
      <ComponentsDataBoard totalData={totalData} tabsContent={tabComponents} />
    </>
  );
}
