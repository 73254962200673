import HttpClient from '@/services/HttpClient';
import { mapToCamelCase } from '@/api/mappers';
import config from '@vgs-config';
import { useQuery } from 'react-query';

type UserInfoData = {
  sub: string;
  vgs_staff: boolean;
  user_id: string;
  preferred_username: string;
};

const getUserInfoData = async (): Promise<UserInfoData> => {
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json, text/plain, */*',
    },
  };
  const url = `${config.keycloakConfig.url}/realms/vgs/protocol/openid-connect/userinfo`;
  const payload = (await HttpClient.get<any>(url, customConfig).then(
    mapToCamelCase,
  )) as UserInfoData;
  return payload;
};

export const useUserInfo = () => {
  const payload = useQuery('userinfo', () => {
    const userInfoData = getUserInfoData();
    return userInfoData;
  });
  return payload;
};
