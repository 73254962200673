import config from '@vgs-config';
import { HttpClient } from '@/services';
import { Response } from './response';
import { mapToCamelCase, mapToSnakeCase } from './mappers';

export interface PersonalizationUserProfile {
  settings: {
    [key: string]: string;
  };
}

class PersonalizationApi {
  static BASE_URL = config.api.personalizationUrl;

  private _config = {
    baseURL: PersonalizationApi.BASE_URL,
  };

  get profilesPath() {
    return '/profiles';
  }

  getUserProfile(userId: string) {
    const url = `${this.profilesPath}/${userId}`;

    return HttpClient.get<Response<PersonalizationUserProfile>>(url, this._config).then(
      ({ data }) => mapToCamelCase(data),
    );
  }

  updateUserProfile(userId: string, data: PersonalizationUserProfile) {
    const url = `${this.profilesPath}/${userId}`;
    const payload = mapToSnakeCase({ data });

    return HttpClient.patch<Response<PersonalizationUserProfile>>(url, payload, this._config).then(
      ({ data }) => mapToCamelCase(data),
    );
  }
}

export default new PersonalizationApi();
