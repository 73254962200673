import React, { FC } from 'react';
import cx from 'classnames';
import { Badge, Tooltip } from '@vgs/ui-core';
import { convertFromCents, formatDate } from '@/utils';
import { filterByDateRangeOnClient, DateRangePicker } from '@vgs/data-table';

export type TransferStatus = 'successful' | 'failed';

export const formatTransferStatus = (value: TransferStatus) => {
  const statuses = {
    successful: 'success',
    failed: 'failed',
  };

  return statuses[value] || value;
};

export const TransferStatusCell: FC<{ value: TransferStatus }> = React.memo((props) => {
  const { value } = props;

  const statuses = {
    successful: 'success',
    failed: 'error',
    default: 'misc',
  };

  const status = statuses[value] || statuses.default;

  return (
    <Badge
      className={cx(
        'tw-uppercase tw-text-white tw-text-xs tw-font-bold tw-rounded-full tw-p-1',
        `tw-bg-${status}-700`,
      )}
    >
      {formatTransferStatus(value)}
    </Badge>
  );
});

const TransferDateCell: FC<{ value: string }> = React.memo((props) => {
  const { value } = props;

  return (
    <Tooltip title={value}>
      <span>{formatDate(value)}</span>
    </Tooltip>
  );
});

const REVERSAL_TRANSFERS_TYPE = 'reversal';
const REVERSAL_TRANSFERS_SOURCE = 'Transfer Reversed';

const handleTransfersSource = (row: any) => {
  if (row?.type === REVERSAL_TRANSFERS_TYPE) return REVERSAL_TRANSFERS_SOURCE;
  return row?.source;
};

export const columns = [
  {
    Header: 'Id',
    accessor: 'id',
    width: 250,
  },
  {
    Header: 'Financial Instrument',
    accessor: handleTransfersSource,
    minWidth: 250,
  },
  {
    Header: 'Gateway',
    id: 'gateway',
    accessor: (row: any) => row.gateway.id,
    width: 200,
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: (row: any) => row.amount,
    Cell: ({ value }: { value: number }) => {
      return <div className="tw-text-right">{convertFromCents(value)}</div>;
    },
    width: 90,
  },
  {
    Header: 'Currency',
    id: 'currency',
    accessor: (row: any) => row.currency,
    width: 100,
  },
  {
    Header: 'State',
    id: 'state',
    accessor: (row: any) => row.gatewayResponse.state,
    Cell: TransferStatusCell,
    width: 80,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: TransferDateCell,
    minWidth: 200,
    Filter: DateRangePicker,
    filter: filterByDateRangeOnClient,
  },
];
