export const PATHS = {
  DASHBOARD: '/',
  FINANCIAL_INSTRUMENTS: '/financial-instruments',
  GATEWAYS: '/gateways',
  GETTING_STARTED: '/getting-started',
  RULES: '/rules',
  TRANSFERS: '/transfers',
  SETTINGS: {
    ROOT: '/settings',
    VAULT: '/vault',
    ORGANIZATION: '/organization',
    USER_PROFILE: '/user-profile',
  },
  CHECKOUT: '/checkout',
  REPORTING: '/reporting',
};

export const getNavLinks = (isProvisioned: boolean) => {
  return [
    {
      label: 'Payment Optimization',
      links: [{ label: 'Dashboard', to: PATHS.DASHBOARD }],
    },
    {
      label: 'Orchestration',
      links: [
        { label: 'Getting Started', to: PATHS.GETTING_STARTED },
        ...(isProvisioned
          ? [
              { label: 'Gateways', to: PATHS.GATEWAYS },
              { label: 'Rules', to: PATHS.RULES },
              { label: 'Financial Instruments', to: PATHS.FINANCIAL_INSTRUMENTS },
              { label: 'Transfers', to: PATHS.TRANSFERS },
              { label: 'Reporting', to: PATHS.REPORTING },
            ]
          : []),
      ],
    },
    {
      label: 'Universal Checkout',
      links: [{ label: 'Overview', to: PATHS.CHECKOUT }],
    },
    {
      label: 'Administration',
      links: [
        { label: 'Vault', to: `${PATHS.SETTINGS.ROOT}${PATHS.SETTINGS.VAULT}` },
        {
          label: 'Organization',
          to: `${PATHS.SETTINGS.ROOT}${PATHS.SETTINGS.ORGANIZATION}`,
        },
      ],
    },
  ];
};
