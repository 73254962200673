import React, { FC, useCallback } from 'react';
import { Button, Modal } from '@vgs/ui-core';

interface DeletePromptProps {
  visible: boolean;
  title: string;
  isLoading: boolean;
  onDelete?: (e: React.MouseEvent) => void;
  onCancel?: (e: React.MouseEvent) => void;
}

const DeletePrompt: FC<DeletePromptProps> = (props) => {
  const { visible, title, isLoading, onDelete, onCancel, children } = props;

  const handleOnCancel = useCallback(
    (e) => {
      e.preventDefault();

      onCancel && onCancel(e);
    },
    [onCancel],
  );

  const handleOnDelete = useCallback(
    (e) => {
      e.preventDefault();

      onDelete && onDelete(e);
    },
    [onDelete],
  );

  const footer = [
    <Button key="cancel" type="ghost_gray" onClick={handleOnCancel}>
      Cancel
    </Button>,
    <Button key="confirm" type="error" onClick={handleOnDelete} disabled={isLoading}>
      Confirm
    </Button>,
  ];

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        visible={visible}
        title={title}
        footer={footer}
        maskClosable={false}
        onCancel={handleOnCancel}
      >
        {children}
      </Modal>
    </div>
  );
};

export default DeletePrompt;
