import { HttpClient } from '@/services';
import config from '@vgs-config';
import { mapToCamelCase } from './mappers';

class BillingApi {
  static BASE_URL = config.api.billing;

  get organizationEndpoint() {
    return '/organizations/';
  }

  getBillingData(orgId?: string) {
    if (orgId) {
      const url = `${BillingApi.BASE_URL}${this.organizationEndpoint}${orgId}`;
      return HttpClient.get(url).then((data) => mapToCamelCase(data));
    }
  }
}

export default new BillingApi();
