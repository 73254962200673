import config from '@vgs-config';
const { docsUrl, demoApplication } = config;

export interface ICheckoutConfig {
  [key: string]: {
    title: string;
    link: string;
    description: string;
    demoAppDescription: string;
    demoAppLink: string;
    links: { label: string; link: string }[];
  };
}

export const checkoutConfig: ICheckoutConfig = {
  web: {
    title: 'VGS Universal Checkout Web',
    link: docsUrl.checkout.web.paymentOptimization,
    description:
      'offers a complete checkout experience that is fully integrated with our payment optimization solution. We enable you to offer a single, customized, consistent experience to your customers across the devices and browsers that you control.',
    links: [
      {
        label: 'Start Building',
        link: docsUrl.checkout.web.startBuilding,
      },
      {
        label: 'Integration Example',
        link: docsUrl.checkout.web.integrationExample,
      },
      {
        label: 'Form Customization',
        link: docsUrl.checkout.web.formCustomization,
      },
    ],
    demoAppDescription:
      'VGS Universal Checkout & Payment Orchestration integration simple demo application.',
    demoAppLink: demoApplication.web,
  },
  iOS: {
    title: 'VGS Universal Checkout iOS SDK',
    link: docsUrl.checkout.ios.sdk,
    description:
      'is a library that allows you to securely collect payment and billing data from your users without having to pass that data through your systems. Get all the benefits with pre-build form while having flexibility with customizable UI components on mobile devices with iOS.',
    links: [
      {
        label: 'Integration',
        link: docsUrl.checkout.ios.integration,
      },
      {
        label: 'Configuration',
        link: docsUrl.checkout.ios.configuration,
      },
      {
        label: 'Debugging',
        link: docsUrl.checkout.ios.debugging,
      },
    ],
    demoAppDescription: 'Need description',
    demoAppLink: demoApplication.ios,
  },
  android: {
    title: 'VGS Universal Checkout Android SDK',
    link: docsUrl.checkout.android.sdk,
    description:
      'is a library that allows you to securely collect payment and billing data from your users without having to pass that data through your systems. Get all the benefits with pre-build form while having flexibility with customizable UI components on mobile devices with Android OS.',
    links: [
      {
        label: 'Integration',
        link: docsUrl.checkout.android.integration,
      },
      {
        label: 'Configuration',
        link: docsUrl.checkout.android.configuration,
      },
      {
        label: 'Debugging',
        link: docsUrl.checkout.android.debugging,
      },
    ],
    demoAppDescription: 'Need description',
    demoAppLink: demoApplication.android,
  },
};
