import { isArray, isNull, isPlainObject } from 'lodash';
import { ArrayOfObjects } from '@/api/mappers-types';

export function isObjectType(obj: unknown): obj is object {
  return isPlainObject(obj) && !isNull(obj);
}

export function isArrayOfObjects(arr: unknown): arr is ArrayOfObjects {
  if (isArray(arr)) {
    const [firstElement] = arr;
    return typeof firstElement === 'object' && !isArray(firstElement) && !isNull(firstElement);
  }
  return false;
}
