import React, { useCallback, useMemo, useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { flatten, map } from 'lodash';
import { Card, Drawer, Popover, Tooltip } from '@vgs/ui-core';
import { DataTable } from '@vgs/data-table';

import { useFinancialInstrumentsApi } from '@/hooks';
import { FinancialInstrument } from '@/api';
import { formatDate } from '@/utils';
import { Loader } from '@/components';
import { columns, getFirstName, getLastName, getType } from './financial-instruments-columns';
import { decamelize } from 'humps';

const popoverStyles = { maxWidth: 450 };

enum FilterNamesExclusions {
  CARD_NAME = 'cardName',
  CARD_NUMBER = 'cardNumber',
  CARD_BRAND = 'cardBrand',
}

export const isFieldExluded = (exclusions: typeof FilterNamesExclusions, field: string) =>
  Object.values(exclusions).some((v) => v === field);

const FinancialInstrumentsWidget = () => {
  const [showFinancialInstrumentDrawer, setShowFinancialInstrumentDrawer] =
    useState<boolean>(false);
  const [selectedFinancialInstrument, setSelectedFinancialInstrument] =
    useState<FinancialInstrument | null>(null);
  const { financialInstrumentsList: financialInstrumentsListWithSearch } =
    useFinancialInstrumentsApi();
  const [filters, setFilters] = useState<{
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
  }>({ globalFilter: undefined, filters: [] });

  const tableColumns = useMemo(() => columns, []);
  const tableFilters = useMemo(() => filters, [filters]);

  const filterableColumns = useMemo(() => {
    return map(
      columns.filter(({ accessor, disableFilters }: any) => !!accessor && !disableFilters),
      'accessor',
    ) as string[];
  }, []);

  const financialInstrumentsList = financialInstrumentsListWithSearch({
    ...filters,
    keys: filterableColumns,
  });

  const { hasNextPage, isFetchingNextPage, isFetching, fetchNextPage } = financialInstrumentsList;

  const financialInstruments = useMemo(() => {
    const data = map(financialInstrumentsList.data?.pages, 'data');

    return flatten(data);
  }, [financialInstrumentsList.data]);

  const handleDrawerClose = useCallback(() => {
    setShowFinancialInstrumentDrawer(false);
  }, []);

  const handleOnSelect = useCallback((row: FinancialInstrument) => {
    setSelectedFinancialInstrument(row);
    setShowFinancialInstrumentDrawer(true);
  }, []);

  const mapToDotNotation = (field: string) => decamelize(field, { separator: '.' });

  const transformFilters = useCallback((filter: { id: string; value: string }) => {
    const transformedFilter = isFieldExluded(FilterNamesExclusions, filter.id)
      ? { ...filter, id: mapToDotNotation(filter.id) }
      : filter;
    return { ...transformedFilter };
  }, []);

  const handleSearch = useCallback(
    (filtersCollection) => {
      const { filters } = filtersCollection;
      const transformedFilters = filters.map(transformFilters);

      const updatedFilterWrapper = { ...filtersCollection, filters: transformedFilters };
      setFilters({ ...updatedFilterWrapper, keys: filterableColumns });
    },
    [filterableColumns, transformFilters],
  );

  if (financialInstrumentsList.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Card className="tw-overflow-hidden tw-h-full tw-bg-white tw-rounded-lg tw-border-neutral-200">
        <div className="tw-flex tw-justify-between tw-mb-4">
          <h1 className="tw-subhead tw-flex tw-items-center">
            <span>Financial Instruments</span>

            <Popover
              content="A Financial Instrument resource represents a payment card, bank account or other financial instrument such as a wallet which can represent a source, or destination of funds."
              overlayInnerStyle={popoverStyles}
            >
              <InfoCircleFilled className="tw-ml-2 tw-cursor-pointer" />
            </Popover>
          </h1>
        </div>

        <DataTable
          columns={tableColumns}
          filters={tableFilters}
          data={financialInstruments}
          onRowSelect={handleOnSelect}
          hasNextPage={hasNextPage}
          onLoadMore={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage || isFetching}
          disableGlobalFilter={true}
          onSearch={handleSearch}
        />
      </Card>

      <Drawer
        title="View Financial Instrument"
        visible={showFinancialInstrumentDrawer}
        onClose={handleDrawerClose}
        width={560}
      >
        <div>
          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Id</div>

              <span className="tw-text-body-b2">{selectedFinancialInstrument?.id}</span>
            </div>
          </section>

          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Created At</div>

              <Tooltip title={selectedFinancialInstrument?.createdAt}>
                <span className="tw-text-body-b2 tw-break-all">
                  {formatDate(selectedFinancialInstrument?.createdAt)}
                </span>
              </Tooltip>
            </div>

            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Updated At</div>

              <Tooltip title={selectedFinancialInstrument?.updatedAt}>
                <span className="tw-text-body-b2 tw-break-all">
                  {formatDate(selectedFinancialInstrument?.updatedAt)}
                </span>
              </Tooltip>
            </div>
          </section>

          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div>
                <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Type</div>

                <span className="tw-text-body-b2 tw-capitalize">
                  {getType(selectedFinancialInstrument)}
                </span>
              </div>
            </div>
          </section>

          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">First Name</div>

              <span className="tw-text-body-b2 tw-capitalize">
                {getFirstName(selectedFinancialInstrument?.card?.name!)}
              </span>
            </div>

            <div className="tw-flex-1">
              <div>
                <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Last Name</div>

                <span className="tw-text-body-b2 tw-capitalize">
                  {getLastName(selectedFinancialInstrument?.card?.name!)}
                </span>
              </div>
            </div>
          </section>

          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Brand</div>

              <span className="tw-text-body-b2 tw-capitalize">
                {selectedFinancialInstrument?.card?.brand}
              </span>
            </div>

            {selectedFinancialInstrument?.card?.countryOfOrigin && (
              <div className="tw-flex-1">
                <div>
                  <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">
                    Country of Origin
                  </div>

                  <span className="tw-text-body-b2 tw-capitalize">
                    {selectedFinancialInstrument?.card?.countryOfOrigin}
                  </span>
                </div>
              </div>
            )}
          </section>
        </div>
      </Drawer>
    </>
  );
};

export default FinancialInstrumentsWidget;
