import React, { useCallback, useMemo, useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { flatten, map } from 'lodash';
import { Card, Button, Drawer, Popover, Input, Form, Select } from '@vgs/ui-core';
import { DataTable } from '@vgs/data-table';
import config from '@vgs-config';

import { ReactComponent as ExternalLink } from '@vgs/assets/icons/link-out.svg';

import { useGatewaysApi, useRulesApi } from '@/hooks';
import { Rule } from '@/api';
import { Loader } from '@/components';
import { columns, RULE_ACTIONS } from './rules-columns';

const popoverStyles = { maxWidth: 450 };

enum DrawerMode {
  CREATE = 'CREATE',
  READ = 'READ',
  EDIT = 'EDIT',
  DEFAULT = 'DEFAULT',
}

const drawerTitles = {
  CREATE: 'Add Rules',
  READ: 'View Rules',
  EDIT: 'Edit Rules',
  DEFAULT: 'View Rules',
};

const RulesWidget = () => {
  const { docsUrl } = config;
  const [showRuleDrawer, setShowRuleDrawer] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<Rule | null>(null);
  const { rulesList: rulesListWithSearch, createRule, updateRule } = useRulesApi();
  const { gatewaysList: gatewaysListWithSearch } = useGatewaysApi();
  const [drawerMode, setDrawerMode] = useState<DrawerMode>(DrawerMode.DEFAULT);
  const [filters, setFilters] = useState<{
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
  }>({ globalFilter: undefined, filters: [] });
  const [ruleForm] = Form.useForm();

  const tableColumns = useMemo(() => columns, []);
  const tableFilters = useMemo(() => filters, [filters]);

  const filterableColumns = useMemo(() => {
    return map(
      columns.filter(({ accessor, disableFilters }: any) => !!accessor && !disableFilters),
      'accessor',
    ) as string[];
  }, []);

  const rulesList = rulesListWithSearch({ ...filters, keys: filterableColumns });

  const { hasNextPage, isFetchingNextPage, isFetching, fetchNextPage } = rulesList;

  const gatewaysList = gatewaysListWithSearch();

  const gateways = useMemo(() => {
    const data = map(gatewaysList.data?.pages, 'data');

    return flatten(data);
  }, [gatewaysList.data]);

  const rules = useMemo(() => {
    const data = map(rulesList.data?.pages, 'data');

    return flatten(data);
  }, [rulesList.data]);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const handleSearch = useCallback(
    (filters) => {
      setFilters({ ...filters, keys: filterableColumns });
    },
    [filterableColumns],
  );

  const handleGatewayDrawer = useCallback(() => {
    ruleForm.resetFields();
    setDrawerMode(DrawerMode.CREATE);
    setShowRuleDrawer(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerMode(DrawerMode.DEFAULT);
    setSelectedRule(null);
    setShowRuleDrawer(false);
  }, []);

  const handleOnSelect = (row: Rule) => {
    setSelectedRule(row);
    setShowRuleDrawer(true);
  };

  const handleUpdateGatewayDrawer = useCallback(() => {
    setDrawerMode(DrawerMode.EDIT);
    ruleForm.setFieldsValue({
      description: selectedRule?.description,
      ordinal: selectedRule?.ordinal,
      criteria: selectedRule?.criteria,
      routeToGateway: selectedRule?.routeToGateway,
    });
    setShowRuleDrawer(true);
  }, [ruleForm, selectedRule]);

  const handleFormReset = useCallback(() => {
    ruleForm.resetFields();
    handleDrawerClose();
  }, [ruleForm, handleDrawerClose]);

  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        await ruleForm.validateFields();

        const payload = ruleForm.getFieldsValue();

        payload.ordinal = +payload.ordinal;

        createRule.mutate(payload, {
          onSuccess: () => handleFormReset(),
        });
      } catch (_) {}
    },
    [ruleForm, createRule, handleFormReset],
  );

  if (rulesList.isLoading) {
    return <Loader />;
  }

  const handleFormUpdate = async () => {
    try {
      const validatedPayload = await ruleForm.validateFields();
      const updatedData = { ...validatedPayload, id: selectedRule?.id };
      updateRule.mutate(updatedData, {
        onSuccess: () => handleFormReset(),
      });
    } catch (_) {}
  };
  const getDrawerTitle = (currentMode: DrawerMode) => drawerTitles[currentMode];
  const writeMode = drawerMode === DrawerMode.EDIT || drawerMode === DrawerMode.CREATE;
  const renderFooterBtnHandlers = () => {
    switch (drawerMode) {
      case DrawerMode.CREATE:
        return (
          <Button
            className="tw-flex tw-align-center tw-ml-4"
            type="primary"
            onClick={handleFormSubmit}
            disabled={createRule.isLoading}
          >
            Submit
          </Button>
        );
      case DrawerMode.EDIT:
        return (
          <Button
            className="tw-flex tw-align-center tw-ml-4"
            type="primary"
            onClick={handleFormUpdate}
            disabled={createRule.isLoading}
          >
            Save
          </Button>
        );
      default:
        return (
          <Button
            className="tw-flex tw-align-center tw-ml-4"
            type="primary"
            onClick={handleUpdateGatewayDrawer}
            disabled={createRule.isLoading}
          >
            Edit
          </Button>
        );
    }
  };

  const getFooter = () => {
    return (
      <div className="tw-flex tw-border-t tw-border-neutral-200 tw-py-4 tw-px-6 tw-justify-end">
        <Button className="tw-flex tw-align-center" type="ghost_gray" onClick={handleFormReset}>
          {drawerMode === DrawerMode.EDIT ? 'Cancel' : 'Close'}
        </Button>
        {renderFooterBtnHandlers()}
      </div>
    );
  };

  const footer = getFooter();

  const rulesTooltip = (
    <div>
      Rules provide a great way to customize how you would like to route, block or retry a transfer
      to a different gateway based on different scenarios easily. A rule can be defined by different
      attributes.{' '}
      <a
        className="tw-p-0 tw-text-primary hover:tw-no-underline"
        href={docsUrl.paymentsOrchestration.rules}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="tw-mr-2">Read More.</span>
      </a>
    </div>
  );

  return (
    <>
      <Card className="tw-overflow-hidden tw-h-full tw-bg-white tw-rounded-lg tw-border-neutral-200">
        <div className="tw-flex tw-justify-between tw-mb-4">
          <h1 className="tw-subhead tw-flex tw-items-center">
            <span>Rules</span>

            <Popover content={rulesTooltip} overlayInnerStyle={popoverStyles}>
              <InfoCircleFilled className="tw-ml-2 tw-cursor-pointer" />
            </Popover>
          </h1>

          <div>
            <Button onClick={handleGatewayDrawer}>Add Rule</Button>
          </div>
        </div>

        <DataTable
          columns={tableColumns}
          filters={tableFilters}
          data={rules}
          onRowSelect={handleOnSelect}
          hasNextPage={hasNextPage}
          onLoadMore={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage || isFetching}
          disableGlobalFilter={true}
          // onSearch={handleSearch}
        />
      </Card>

      <Drawer
        title={getDrawerTitle(drawerMode)}
        visible={showRuleDrawer}
        onClose={handleDrawerClose}
        width={560}
        footer={footer}
        footerStyle={{ padding: 0, border: 'none' }}
      >
        <Form
          name="rule"
          form={ruleForm}
          initialValues={{
            action: 'route',
            description: selectedRule?.description,
            ordinal: selectedRule?.ordinal,
            criteria: selectedRule?.criteria,
            routeToGateway: selectedRule?.routeToGateway,
          }}
        >
          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Description</div>

              {writeMode ? (
                <Form.Item
                  className="tw-m-0"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter rule short description',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <span className="tw-text-body-b2">{selectedRule?.description}</span>
              )}
            </div>

            <div className="tw-flex-1 tw-ml-4">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Priority</div>

              {writeMode ? (
                <Form.Item
                  className="tw-m-0"
                  name="ordinal"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter rule priority',
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              ) : (
                <span className="tw-text-body-b2">{selectedRule?.ordinal}</span>
              )}
            </div>
          </section>

          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Action</div>

              {writeMode ? (
                <Form.Item name="action" className="tw-m-0">
                  <Select className="tw-flex">
                    {Object.keys(RULE_ACTIONS).map((action) => (
                      <Select.Option key={action} value={action}>
                        {(RULE_ACTIONS as any)[action]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <span className="tw-text-body-b2 tw-capitalize">{selectedRule?.action}</span>
              )}
            </div>

            <div className="tw-flex-1 tw-ml-4">
              <div>
                <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Gateway</div>

                {(gateways && drawerMode === DrawerMode.EDIT) ||
                drawerMode === DrawerMode.CREATE ? (
                  <Form.Item
                    className="tw-m-0"
                    name="routeToGateway"
                    rules={[
                      {
                        required: true,
                        message: 'Please select gateway',
                      },
                    ]}
                  >
                    <Select className="tw-flex" showSearch>
                      {gateways.map(({ id }) => (
                        <Select.Option key={id} value={id}>
                          {id}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <span className="tw-text-body-b2">{selectedRule?.routeToGateway}</span>
                )}
              </div>
            </div>
          </section>

          <section className="tw-flex tw-mb-4">
            <div className="tw-flex-1">
              <div className="tw-flex tw-items-center tw-text-body-bs2 tw-mb-1">Conditions</div>

              {writeMode ? (
                <>
                  <Form.Item
                    className="tw-m-0"
                    name="criteria"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter rule definition',
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>

                  {drawerMode === DrawerMode.CREATE && (
                    <div className="tw-inline-block tw-text-body-b3">
                      <span className="tw-text-body-bb3">Example:</span> "transfer.currency == 'EUR'
                      && transfer.amount {'>'} 10000".{' '}
                      <a
                        className="tw-flex tw-items-center tw-p-0 tw-text-primary hover:tw-no-underline"
                        href={docsUrl.paymentsOrchestration.rulesSyntax}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="tw-mr-2"> Learn more about formatting</span>
                        <ExternalLink />
                      </a>
                    </div>
                  )}
                </>
              ) : (
                <span className="tw-text-body-b2">{selectedRule?.criteria}</span>
              )}
            </div>
          </section>
        </Form>
      </Drawer>
    </>
  );
};

export default RulesWidget;
