import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

import { AuthProvider } from '@vgs/auth';
import { HeapAnalyticsProvider, GoogleTagManagerProvider } from '@vgs/analytics';
import config from '@vgs-config';
import ErrorBoundary from '@/ErrorBoundary';
import { browserHistory, queryClient } from '@/utils';
import { CurrentVaultProvider } from '@/hooks';
import { FlagsProvider } from 'flagged';

const { worker } = require('./mocks/browser');

worker.start({
  onUnhandledRequest: 'bypass',
});

const { env } = config;

ReactDOM.render(
  <ErrorBoundary>
    <FlagsProvider
      features={{
        isDev: env === 'development',
        isCanary: env === 'canary',
        isProd: env === 'production',
      }}
    >
      <AuthProvider config={config.keycloakConfig}>
        <Router history={browserHistory}>
          <HeapAnalyticsProvider heapTrackingId={config.heapTrackingId}>
            <GoogleTagManagerProvider gtmConfig={config.googleTagManagerConfig}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />

                <CurrentVaultProvider>
                  <App />
                </CurrentVaultProvider>
              </QueryClientProvider>
            </GoogleTagManagerProvider>
          </HeapAnalyticsProvider>
        </Router>
      </AuthProvider>
    </FlagsProvider>
    ,
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
