import { rest } from 'msw';

import response from '../data/transfers.json';

const transfersListResolver = (req, res, ctx) => {
  return res(ctx.json(response));
};

const transfersUrlRegexp = /https:\/\/payments.(dev|sandbox|live).verygoodsecurity.app\/transfers/;

export const fetchTransfersList = rest.get(transfersUrlRegexp, transfersListResolver);
