import React from 'react';
import cx from 'classnames';

const Loader: React.FC<{ className?: string }> = (props) => {
  const { className } = props;

  return (
    <div className={cx('tw-flex tw-items-center tw-justify-center tw-h-full', className)}>
      <div className="tw-text-body-b3">Loading...</div>
    </div>
  );
};

export default Loader;
