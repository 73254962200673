import { rest } from 'msw';

import mock from '../data/financial-instruments.json';

const financialInstrumentsListResolver = (req, res, ctx) => {
  return res(ctx.json(mock));
};

const financialInstrumentsUrlRegexp =
  /https:\/\/payments.(dev|sandbox|live).verygoodsecurity.app\/financial_instruments/;

export const fetchFinancialInstrumentsList = rest.get(
  financialInstrumentsUrlRegexp,
  financialInstrumentsListResolver,
);
