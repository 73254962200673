import React from 'react';

import { initRollbar, sendErrorToRollbar } from '@/utils/rollbar';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidMount() {
    initRollbar();
  }

  componentDidCatch(error: any) {
    // should probably be sendCriticalToRollbar, after some testing period
    sendErrorToRollbar(error);

    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? (
      <div className="tw-text-center tw-mt-12">
        Sorry, something went wrong. Try reloading page.
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
