import { upperCase, filter, isArray, isBoolean, isNil } from 'lodash';
import { camelize } from 'humps';

import * as multiplexingHandlers from './multiplexing';
import config from './config/default.json';

const PATHNAME_REGEX = /^((.*:)?\\?\/\\?\/)?(([A-Za-z0-9\-.[\]+()*|]+)(:[0-9]+)?)?\\?\/?(.*)\/?$/;

/*
MSW Handler example

info:
method: "GET"
path: /https:\/\/([a-z0-9]+)*.multiplexing.(dev|sandbox|live).verygoodsecurity.app\/financial_instruments/
*/

const getEnabledHandlers = (handlers) => {
  const filtered = filter(handlers, (handler) => {
    const {
      info: { method, path },
    } = handler;
    const url = new RegExp(path).toString().replace(/^\/|\/$/g, '');
    const pathname = camelize(url.match(PATHNAME_REGEX)[6]);
    const value = config[pathname];

    return (
      isNil(value) ||
      (isBoolean(value) && !!value) ||
      (isArray(value) && value.map(upperCase).includes(method))
    );
  });

  return filtered;
};

export const handlers = [...getEnabledHandlers(Object.values(multiplexingHandlers))];
