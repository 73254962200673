import { useCallback } from 'react';
import { Button, Card } from '@vgs/ui-core';
import config from '@vgs-config';
import { useCurrentVault } from '@/hooks';

const TENANT_ID_PLACEHOLDER = '{{TENANT_ID}}';

const VaultSettings = () => {
  const {
    servicesUrl: { vault: vaultUrl },
  } = config;

  const { vault } = useCurrentVault();

  const url = `${vaultUrl}/dashboard/v/${TENANT_ID_PLACEHOLDER}/settings/general`;

  const onRedirect = useCallback(() => {
    if (vault?.id) {
      window.location.assign(url.replace(TENANT_ID_PLACEHOLDER, vault.id));
    }
  }, [vault, url]);

  return (
    <Card
      title="Vault Settings"
      className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200"
    >
      <div className="tw-flex tw-flex-col tw-items-center tw-text-body-b2 tw-p-8">
        <p className="tw-mb-6">Vault Settings are now managed in the VGS Vault.</p>

        <Button className="tw-flex tw-align-center tw-mr-4" onClick={onRedirect}>
          Go to Vault
        </Button>
      </div>
    </Card>
  );
};

export default VaultSettings;
