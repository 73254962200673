import { FC, useContext, useState } from 'react';
import './filters.scss';
import { Button, Popover, Select } from '@vgs/ui-core';
import { ReactComponent as FilterIcon } from '@vgs/assets/icons/filter-logo.svg';
import { ReactComponent as ResetFilterIcon } from '@vgs/assets/icons/reset-filter-icon.svg';
import {
  TIME_OPTIONS,
  PAYMENT_REQUEST_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  DEFAULT_TIME_OPTION,
} from './filter-constants';
import { partial } from 'lodash';
import { actionTypes, actionTypesOptions } from '../../actionTypes';
import { FiltersContext } from './filter-context';

const { Option } = Select;

type SelectOptionType = {
  name: string;
  value: string;
};

const SELECT_PLACEHOLDER = 'Select filter';

interface CustomSelectProps {
  options: SelectOptionType[];
  placeholder: string;
  onChange?: (value: string, option: any | any[]) => void | null;
  value?: string | SelectOptionType | null;
  selectedValue?: string | SelectOptionType | null;
}
const CustomSelect: FC<CustomSelectProps> = ({
  options,
  placeholder,
  onChange,
  value,
  selectedValue,
}) => {
  const currentSelectedValue = value
    ? value
    : selectedValue === null
    ? options[0].value
    : selectedValue;

  return (
    <Select
      className="filter-selector"
      placeholder={placeholder}
      onChange={onChange}
      value={
        typeof currentSelectedValue === 'object' ? currentSelectedValue.value : currentSelectedValue
      }
    >
      {options.map(({ name, value }) => (
        <Option key={value} value={value}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

const Filter = (props: any) => {
  //@ts-ignore
  const { pullUsageStats } = props;
  const { filters, dispatch } = useContext(FiltersContext);

  const {
    timeFilter,
    paymentRequestFilter,
    paymentMethodFilter,
    selectedPaymentMethodFilter,
    selectedPaymentRequestFilter,
  } = filters;

  const isFiltersApplied = Object.values({
    paymentMethod: filters.paymentMethodFilter,
    timeFilter: filters.timeFilter === DEFAULT_TIME_OPTION ? null : filters.timeFilter,
    paymentRequestFilter: filters.paymentRequestFilter,
  }).filter((item) => item !== null).length;

  const filterNotification = isFiltersApplied ? 'enabled-filters-circle' : '';

  const [isVisible, setVisible] = useState(false);

  const applyPaymentRequestFilter = partial(dispatch, {
    type: actionTypes.APPLY_FILTERS as actionTypesOptions,
    filterName: `paymentRequestFilter`,
    payload: selectedPaymentRequestFilter,
  });
  const applyPaymentMethodFilter = partial(dispatch, {
    type: actionTypes.APPLY_FILTERS as actionTypesOptions,
    filterName: `paymentMethodFilter`,
    payload: selectedPaymentMethodFilter,
  });

  const updateFilterState = () => {
    applyPaymentRequestFilter();
    applyPaymentMethodFilter();
    setVisible(false);
  };

  const handleApply = () => {
    // invoke the callback after at the end of callstack
    // to be sure that filters' states is up to date
    updateFilterState();
    setTimeout(() => {
      pullUsageStats();
    }, 0);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  // TODO use enum values
  const handleReset = () => {
    dispatch({ type: 'RESET_FILTERS', payload: '', filterName: '' });
    setVisible(false);
  };

  const handleChangeTimeSelect = (value: string) => {
    dispatch({ type: `APPLY_FILTERS`, filterName: `timeFilter`, payload: value });
  };

  const handleChangePaymentRequestSelect = (value: string) => {
    dispatch({
      type: 'SELECT_FILTER',
      filterName: `selectedPaymentRequestFilter`,
      payload: value,
    });
  };

  const handleChangePaymentMethodSelect = (value: string) => {
    dispatch({
      type: 'SELECT_FILTER',
      filterName: `selectedPaymentMethodFilter`,
      payload: value,
    });
  };

  return (
    <div className="filter-panel">
      <CustomSelect
        options={TIME_OPTIONS}
        placeholder="Select filter"
        value={timeFilter}
        onChange={handleChangeTimeSelect}
      />
      <Popover
        content={
          <div className="wrapper tw-flex">
            <div>
              <CustomSelect
                options={PAYMENT_REQUEST_OPTIONS}
                placeholder={SELECT_PLACEHOLDER}
                value={paymentRequestFilter}
                onChange={handleChangePaymentRequestSelect}
                selectedValue={selectedPaymentRequestFilter}
              />
              <CustomSelect
                options={PAYMENT_METHOD_OPTIONS}
                placeholder="Select filter"
                value={paymentMethodFilter}
                onChange={handleChangePaymentMethodSelect}
                selectedValue={selectedPaymentMethodFilter}
              />
              <div className="button-row">
                <Button className="cancel-btn" type="link" onClick={handleReset}>
                  <span className="tw-flex tw-items-center tw-w-12 tw-justify-between">
                    <ResetFilterIcon />
                    Reset
                  </span>
                </Button>
                <Button className="cancel-btn" type="link" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button onClick={handleApply} type="primary">
                  Apply
                </Button>
              </div>
            </div>
          </div>
        }
        trigger="click"
        visible={isVisible}
        onVisibleChange={handleVisibleChange}
      >
        <span className="tw-flex tw-items-center tw-gap-4">
          <Button className="more-filters__button" type="ghost_gray">
            More Filters
            <div className={filterNotification}></div>
            <FilterIcon className="filter-icon" />
          </Button>
        </span>
      </Popover>
    </div>
  );
};

export default Filter;
