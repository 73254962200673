import { createContext, FC, useReducer } from 'react';
import { initialFiltersState } from './filter-constants';
import { filtersReducer } from './filters-reducer';

type UsageReportsFilterContext = {
  filters: typeof initialFiltersState;
  dispatch: React.Dispatch<any>;
};

type FilterProviderProps = {
  children: JSX.Element;
};

const FiltersContext = createContext<UsageReportsFilterContext>({
  filters: initialFiltersState,
  dispatch: () => undefined,
});

const FilterProvider: FC<FilterProviderProps> = ({ children }) => {
  const [filters, dispatch] = useReducer(filtersReducer, initialFiltersState);
  return (
    //@ts-ignore
    <FiltersContext.Provider value={{ filters, dispatch }}>{children}</FiltersContext.Provider>
  );
};

export { FiltersContext, FilterProvider };
