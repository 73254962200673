import React, { useCallback } from 'react';
import { Button, Card } from '@vgs/ui-core';
import config from '@vgs-config';

const UserProfileSettings = () => {
  const {
    servicesUrl: { vault },
  } = config;

  const url = `${vault}/my-account`;

  const onRedirect = useCallback(() => {
    window.location.assign(url);
  }, [url]);

  return (
    <Card
      title="User Profile Settings"
      className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200"
    >
      <div className="tw-flex tw-flex-col tw-items-center tw-text-body-b2 tw-p-8">
        <p className="tw-mb-6">User Profile Settings are now managed in the VGS Vault.</p>

        <Button className="tw-flex tw-align-center tw-mr-4" onClick={onRedirect}>
          Go to Vault
        </Button>
      </div>
    </Card>
  );
};

export default UserProfileSettings;
