import { map, get } from 'lodash';
import config from '@vgs-config';
import { HttpClient } from '@/services';
import { mapToCamelCase } from './mappers';

interface SSOConfig {
  enabled: boolean;
  metadataUrl?: string;
  idpSignInOnly: boolean;
  idpAlias?: string;
}

export interface Organization {
  active: boolean;
  id?: string;
  identifier: string;
  internalId: string;
  name: string;
  permissions: ['read', 'write', 'admin'];
  ssoSamlConfig: SSOConfig;
  state: 'ACTIVE' | 'PENDING_ACTIVATION' | 'INCOMPLETE';
}

interface Attributes<T> {
  attributes: T;
  id: string;
}

export interface Vault {
  id?: string;
  environment: 'SANDBOX' | 'LIVE';
  identifier: string;
  name: string;
  organizationId?: string;
}

class AccountsApi {
  static BASE_URL = config.api.accountsUrl;

  private _config = {
    baseURL: AccountsApi.BASE_URL,
  };

  constructor() {
    this.getOrganizations = this.getOrganizations.bind(this);
    this.getVaults = this.getVaults.bind(this);
  }

  get organizationsPath() {
    return '/organizations';
  }

  get vaultsPath() {
    return '/vaults';
  }

  getOrganizations() {
    const url = `${this.organizationsPath}`;

    return HttpClient.get<Array<Attributes<Organization>>>(url, this._config).then(({ data }) => {
      const normalized = mapToCamelCase(data);
      const withIds = (org: Attributes<Organization>) => ({
        ...get(org, 'attributes'),
        id: org.id,
      });

      return map(normalized, withIds);
    });
  }

  getVaults() {
    const url = `${this.vaultsPath}`;

    return HttpClient.get<Array<Attributes<Vault>>>(url, this._config).then(({ data }) => {
      const normalized = mapToCamelCase(data);

      const withIds = (vault: Attributes<Vault>) => ({
        ...get(vault, 'attributes'),
        id: vault.id,
        organizationId: get(vault, ['relationships', 'organization', 'data', 'id']),
      });

      return map(normalized, withIds);
    });
  }
}

export default new AccountsApi();
