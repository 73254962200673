import { useInfiniteQuery, useMutation } from 'react-query';
import { notification } from '@vgs/ui-core';
import { MultiplexingApi, Response, Rule } from '@/api';
import { queryClient } from '@/utils';

export const useRulesApi = () => {
  const fetchRules = ({ pageParam: page, queryKey }: { pageParam?: number; queryKey: any }) => {
    const [, { filter }] = queryKey;

    return MultiplexingApi.getRulesList({ page, filter }) as Promise<Response<Rule[]>>;
  };

  const useInfiniteQueryWithSearch = (filter?: {
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
    keys: string[];
  }) => {
    return useInfiniteQuery(['rules', { filter }], fetchRules, {
      getNextPageParam: ({ meta }: Response<Rule[]>) => {
        const { nextPage } = meta || {};

        return nextPage;
      },
    });
  };

  const createRule = useMutation((data: Rule) => MultiplexingApi.createRule(data), {
    onSuccess: (rule) => {
      queryClient.setQueryData('rules', (prev: any) => {
        const { pages } = prev;
        const [lastPage] = pages.slice(-1);
        const nextPage = { ...lastPage, data: [...lastPage.data, rule] };

        return { ...prev, pages: [...prev.pages.slice(0, -1), nextPage] };
      });

      notification.success({
        message: 'Rule have been successfully created',
      });
    },
    onError: () =>
      notification.error({
        message: 'Failed to create rule',
      }),
  });

  const deleteRule = useMutation((id: string) => MultiplexingApi.deleteRule(id), {
    onSuccess: (result, id) => {
      queryClient.setQueryData('rules', (prevRules) => {
        // @ts-ignore
        const { pages, pageParams } = prevRules;

        const nextPages =
          pages.map((page: Response<Array<Rule & { id: string }>>) => {
            const data = page.data.filter((rule) => rule.id !== id);

            return { ...page, data };
          }) ?? [];

        return { pages: nextPages, pageParams };
      });

      notification.success({
        message: 'Rule has been successfully deleted',
      });
    },
    onError: (err: TypeError) => {
      notification.error({
        message: err.message,
      });
    },
  });

  const updateRule = useMutation((data: Rule) => MultiplexingApi.updateRule(data), {
    onSuccess: (rule) => {
      queryClient.setQueryData('rules', (prevRules) => {
        // @ts-ignore
        const { pages } = prevRules;

        const nextPages = pages.map((page: Response<Rule[]>) => {
          const data = page.data.map((payload) =>
            // @ts-ignore
            payload.id === rule?.id ? { ...rule } : payload,
          );
          return { ...page, data };
        });

        return { pages: nextPages };
      });

      notification.success({
        message: 'Rules have been successfully updated',
      });
    },
    onError: (err: TypeError) => {
      notification.error({
        message: err.message,
      });
    },
  });

  return {
    rulesList: useInfiniteQueryWithSearch,
    createRule,
    deleteRule,
    updateRule,
  };
};
