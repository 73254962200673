import { useQuery } from 'react-query';
import { BillingApi } from '@/api';
import { isNil } from 'lodash';
const QUERY_STALE_TIME = 3 * 1000;

export const getBillingModel = (billingPayload: any) => {
  const { attributes } = !isNil(billingPayload?.data) && billingPayload?.data;
  const { billingModel } = !isNil(attributes) && attributes;
  return billingModel;
};

// TODO add typings
export const useBilling = (id?: string, options?: any) => {
  const response = useQuery(['billingData', id], async () => await BillingApi.getBillingData(id), {
    staleTime: QUERY_STALE_TIME,
    ...options,
  });
  return response;
};
