import { QueryClient } from 'react-query';

export enum QueryType {
  ORGANIZATIONS = 'organizations',
  VAULT_STATUS = 'status',
  VAULTS = 'vaults',
}

export default new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
  },
});
