import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { notification } from '@vgs/ui-core';
import { Gateway, MultiplexingApi, Response } from '@/api';
import { queryClient } from '@/utils';

export const useGatewaysApi = () => {
  const fetchGateways = ({ pageParam: page, queryKey }: { pageParam?: number; queryKey: any }) => {
    const [, { filter }] = queryKey;

    return MultiplexingApi.getGatewaysList({ page, filter }) as Promise<Response<Gateway[]>>;
  };

  const useInfiniteQueryWithSearch = (filter?: {
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
    keys: string[];
  }) => {
    return useInfiniteQuery(['gateways', { filter }], fetchGateways, {
      getNextPageParam: ({ meta }: Response<Gateway[]>) => {
        const { nextPage } = meta || {};

        return nextPage;
      },
    });
  };

  const gatewayOptionsList = useQuery('gateway-options', MultiplexingApi.getGatewayOptionsList, {
    staleTime: Infinity,
  });

  const createGateway = useMutation((data: Gateway) => MultiplexingApi.createGateway(data), {
    onSuccess: (gateway) => {
      queryClient.setQueriesData('gateways', (prev: any) => {
        const { pages } = prev;
        const [lastPage] = pages.slice(-1);
        const nextPage = { ...lastPage, data: [...lastPage.data, gateway] };

        return { ...prev, pages: [...prev.pages.slice(0, -1), nextPage] };
      });

      notification.success({
        message: 'Gateway have been successfully created',
      });
    },
    onError: () =>
      notification.error({
        message: 'Failed to create gateway',
      }),
  });

  const updateGateway = useMutation((data: Gateway) => MultiplexingApi.updateGateway(data), {
    onSuccess: (gateway) => {
      queryClient.setQueriesData('gateways', (prevGateways) => {
        // @ts-ignore
        const { pages } = prevGateways;
        const nextPages = pages.map((page: Response<Gateway[]>) => {
          const data = page.data.map((payload) =>
            payload.id === gateway?.id ? { ...gateway } : payload,
          );
          return { ...page, data };
        });
        return { pages: nextPages };
      });

      notification.success({
        message: 'Gateway has been successfully updated',
      });
    },
    onError: (err: TypeError) => {
      notification.error({
        message: err.message,
      });
    },
  });

  const deleteGateway = useMutation((id: string) => MultiplexingApi.deleteGateway(id), {
    onSuccess: (result, id) => {
      queryClient.setQueriesData('gateways', (prevGateways) => {
        // @ts-ignore
        const { pages, pageParams } = prevGateways;

        const nextPages =
          pages.map((page: Response<Gateway[]>) => {
            const data = page.data.filter((gateway) => gateway.id !== id);

            return { ...page, data };
          }) ?? [];

        return { pages: nextPages, pageParams };
      });

      notification.success({
        message: 'Gateway has been successfully deleted',
      });
    },
    onError: (err: TypeError) => {
      notification.error({
        message: err.message,
      });
    },
  });

  return {
    gatewaysList: useInfiniteQueryWithSearch,
    gatewayOptionsList,
    createGateway,
    deleteGateway,
    updateGateway,
  };
};
