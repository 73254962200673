import { rest } from 'msw';

import response from '../data/rules.json';

const rulesListResolver = (req, res, ctx) => {
  return res(ctx.json(response));
};

const rulesUrlRegexp = /https:\/\/payments.(dev|sandbox|live).verygoodsecurity.app\/rules/;

export const fetchRulesList = rest.get(rulesUrlRegexp, rulesListResolver);
