import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { flatten, map } from 'lodash';
import { Card, Button, Drawer, Input, Popover, Select, Form, Switch } from '@vgs/ui-core';
import { DataTable } from '@vgs/data-table';
import config from '@vgs-config';

import { useGatewaysApi } from '@/hooks';
import { Gateway } from '@/api';
import { Loader } from '@/components';
import { TypesGenerator, getUniqueCurrencyCodes, formatFieldToLabel } from '@/utils';
import { columns, formatGatewayType } from './gateways-columns';

const popoverStyles = { maxWidth: 450 };

enum DrawerMode {
  CREATE = 'CREATE',
  READ = 'READ',
  EDIT = 'EDIT',
}

const drawerTitles = {
  CREATE: 'Add Gateway',
  READ: 'View Gateway',
  EDIT: 'Edit Gateway',
};

const getDrawerTitle = (currentMode: DrawerMode) => drawerTitles[currentMode];

const GatewaysWidget = () => {
  const [showGatewayDrawer, setShowGatewayDrawer] = useState<boolean>(false);
  const [drawerMode, setDrawerMode] = useState<DrawerMode>(DrawerMode.READ);
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(null);
  const [gatewayCredentialOptions, setGatewayCredentialOptions] = useState([]);
  const {
    gatewaysList: gatewaysListWithSearch,
    gatewayOptionsList,
    createGateway,
    updateGateway,
  } = useGatewaysApi();
  const [gatewayForm] = Form.useForm();
  const [gatewayCredentialOptionsVariant, setGatewayCredentialOptionsVariant] = useState<number>(0);
  const [filters, setFilters] = useState<{
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
  }>({ globalFilter: undefined, filters: [] });

  const { docsUrl } = config;

  const tableColumns = useMemo(() => columns, []);
  const tableFilters = useMemo(() => filters, [filters]);

  const filterableColumns = useMemo(() => {
    return map(
      columns.filter(({ accessor, disableFilters }: any) => !!accessor && !disableFilters),
      'accessor',
    ) as string[];
  }, []);

  const gatewaysList = gatewaysListWithSearch({ ...filters, keys: filterableColumns });
  const { hasNextPage, isFetchingNextPage, isFetching, fetchNextPage } = gatewaysList;

  const updateGatewayCredentialOptions = useCallback(
    (type?: string) => {
      const options = TypesGenerator.getProperty(gatewayOptionsList.data, `${type}.config`);
      setGatewayCredentialOptions(options || []);
    },
    [gatewayOptionsList.data, setGatewayCredentialOptions],
  );

  const gateways = useMemo(() => {
    const data = map(gatewaysList.data?.pages, 'data');

    return flatten(data);
  }, [gatewaysList.data]);

  useEffect(() => {
    if (selectedGateway?.config) {
      // @ts-ignore
      setGatewayCredentialOptions([selectedGateway.config]);
    } else {
      updateGatewayCredentialOptions(selectedGateway?.type);
    }
  }, [
    selectedGateway,
    selectedGateway?.config,
    selectedGateway?.type,
    gatewayOptionsList.data,
    updateGatewayCredentialOptions,
  ]);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const handleSearch = useCallback(
    (filters) => {
      setFilters({ ...filters, keys: filterableColumns });
    },
    [filterableColumns],
  );

  const handleGatewayDrawer = useCallback(() => {
    gatewayForm.resetFields();
    setSelectedGateway(null);
    setDrawerMode(DrawerMode.CREATE);
    setShowGatewayDrawer(true);
  }, [gatewayForm]);

  const handleUpdateGatewayDrawer = useCallback(() => {
    setDrawerMode(DrawerMode.EDIT);
    setShowGatewayDrawer(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerMode(DrawerMode.READ);
    setShowGatewayDrawer(false);

    if (DrawerMode.EDIT) {
      setSelectedGateway(selectedGateway);
    }
  }, [selectedGateway]);

  const handleOnSelect = useCallback(
    (row: Gateway) => {
      setSelectedGateway(row);
      gatewayForm.setFieldsValue(row);
      setShowGatewayDrawer(true);
    },
    [gatewayForm],
  );

  const handleOnTypeSelect = useCallback(
    (type) => {
      updateGatewayCredentialOptions(type);
      if (type !== selectedGateway?.type) {
        gatewayForm.resetFields(['config']);
      } else {
        gatewayForm.setFieldsValue({ config: selectedGateway?.config });
      }
    },
    [gatewayForm, selectedGateway?.config, selectedGateway?.type, updateGatewayCredentialOptions],
  );
  const handleOnCredentialOptionsVariantSelect = useCallback((variant) => {
    setGatewayCredentialOptionsVariant(variant);
  }, []);

  const handleFormReset = useCallback(() => {
    gatewayForm.resetFields();
    setGatewayCredentialOptions([]);
    handleDrawerClose();
  }, [gatewayForm, handleDrawerClose]);

  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        await gatewayForm.validateFields();

        const payload = gatewayForm.getFieldsValue();
        createGateway.mutate(payload, {
          onSuccess: () => handleFormReset(),
        });
      } catch (error) {
        console.log(error);
      }
    },
    [gatewayForm, createGateway, handleFormReset],
  );

  const handleFormUpdate = async () => {
    try {
      await gatewayForm.validateFields();
      const payload = gatewayForm.getFieldsValue();
      const updatedData = { ...payload, id: selectedGateway?.id };
      updateGateway.mutate(updatedData, {
        onSuccess: () => handleFormReset(),
      });
    } catch (_) {}
  };

  const currencyCodes = useMemo(getUniqueCurrencyCodes, []);
  const writeMode = drawerMode === DrawerMode.CREATE || drawerMode === DrawerMode.EDIT;

  if (gatewaysList.isLoading) {
    return <Loader />;
  }

  const renderFooterBtnHandlers = () => {
    switch (drawerMode) {
      case DrawerMode.CREATE:
        return (
          <Button
            className="tw-flex tw-align-center tw-ml-4"
            type="primary"
            onClick={handleFormSubmit}
            disabled={createGateway.isLoading}
          >
            Submit
          </Button>
        );
      case DrawerMode.EDIT:
        return (
          <Button
            className="tw-flex tw-align-center tw-ml-4"
            type="primary"
            onClick={handleFormUpdate}
            disabled={createGateway.isLoading}
          >
            Save
          </Button>
        );
      default:
        return (
          <Button
            className="tw-flex tw-align-center tw-ml-4"
            type="primary"
            onClick={handleUpdateGatewayDrawer}
            disabled={createGateway.isLoading}
          >
            Edit
          </Button>
        );
    }
  };

  const getFooter = () => {
    return (
      <div className="tw-flex tw-border-t tw-border-neutral-200 tw-py-4 tw-px-6 tw-justify-end">
        <Button className="tw-flex tw-align-center" type="ghost_gray" onClick={handleFormReset}>
          {drawerMode === DrawerMode.EDIT ? 'Cancel' : 'Close'}
        </Button>
        {renderFooterBtnHandlers()}
      </div>
    );
  };

  const gatewaysTooltip = (
    <div>
      A gateway represents a PSP (Payment Service Provider) with specific configurations. Before
      making any transfer, you need to provide at least a default gateway.{' '}
      <a
        className="tw-p-0 tw-text-primary hover:tw-no-underline"
        href={docsUrl.paymentsOrchestration.gateways}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="tw-mr-2">Read More.</span>
      </a>
    </div>
  );

  const maskField = (field: string) => {
    if (!field) return field;

    if (field?.length <= 4) return field?.replace(/((\D)|(\d))/g, '*');
    return field?.replaceAll(/.(?=.{4})/g, '*');
  };

  const footer = getFooter();

  const currentDrawer = (
    <Drawer
      title={getDrawerTitle(drawerMode)}
      visible={showGatewayDrawer}
      onClose={handleDrawerClose}
      width={560}
      footer={footer}
      footerStyle={{ padding: 0, border: 'none' }}
    >
      <Form
        name="gateway"
        form={gatewayForm}
        initialValues={{
          defaultCurrency: 'USD',
          defaultGateway: !gateways.length,
        }}
      >
        <section className="tw-mb-4">
          <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">Identifier</div>
          {drawerMode === DrawerMode.CREATE ? (
            <Form.Item
              name="id"
              rules={[
                {
                  required: true,
                  message: 'Please enter gateway identifier',
                },
                {
                  pattern: new RegExp('^[a-zA-Z0-9-_]+$'),
                  message: 'Invalid identifier',
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : (
            <span className="tw-text-body-b2">{selectedGateway?.id}</span>
          )}
        </section>

        <section className="tw-mb-4">
          <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">Default Currency</div>
          {writeMode ? (
            <Form.Item name="defaultCurrency">
              <Select className="tw-flex" showSearch>
                {Object.keys(currencyCodes).map((code) => (
                  <Select.Option key={code} value={code}>
                    {code} - {currencyCodes[code]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            selectedGateway?.defaultCurrency && (
              <span className="tw-text-body-b2">
                {selectedGateway.defaultCurrency} -{' '}
                {currencyCodes[selectedGateway!.defaultCurrency]}
              </span>
            )
          )}
        </section>

        <section className="tw-mb-4">
          <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">Default Gateway</div>
          <Form.Item name="defaultGateway" valuePropName="checked" className="tw-mb-4">
            <Switch disabled={drawerMode === DrawerMode.READ || gateways.length <= 1} />
          </Form.Item>
        </section>

        <section className="tw-mb-4">
          <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">Gateway Type</div>
          {gatewayOptionsList.data && writeMode ? (
            <Form.Item
              initialValue={selectedGateway?.config?.type}
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select Payment provider',
                },
              ]}
            >
              <Select className="tw-flex" showSearch onChange={handleOnTypeSelect}>
                {Object.keys(gatewayOptionsList.data as any)?.map((gatewayType) => (
                  <Select.Option key={gatewayType} value={gatewayType}>
                    {(gatewayOptionsList.data as any)[gatewayType].name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <span className="tw-text-body-b2">
              {formatGatewayType(gatewayOptionsList.data, selectedGateway?.type)}
            </span>
          )}
        </section>
        {drawerMode && gatewayCredentialOptions.length > 1 && (
          <section className="tw-mb-4">
            <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1">
              PSP Config Variant
            </div>

            <Select
              className="tw-flex"
              value={gatewayCredentialOptionsVariant}
              onChange={handleOnCredentialOptionsVariantSelect}
            >
              {gatewayCredentialOptions?.map((_, variant) => (
                <Select.Option key={variant} value={variant}>
                  Option {variant + 1}
                </Select.Option>
              ))}
            </Select>
          </section>
        )}
        {Object.keys(gatewayCredentialOptions[gatewayCredentialOptionsVariant] || {})?.map(
          (field) => {
            return (
              <section key={field} className="tw-mb-4">
                <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1 tw-capitalize">
                  {formatFieldToLabel(field)}
                </div>
                {drawerMode === DrawerMode.EDIT || drawerMode === DrawerMode.CREATE ? (
                  <Form.Item
                    name={['config', field]}
                    rules={[
                      {
                        required: true,
                        message: `Please enter ${field}`,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ) : (
                  <span className="tw-text-body-b2 tw-break-all">
                    {maskField(selectedGateway?.config[field])}
                  </span>
                )}
              </section>
            );
          },
        )}
      </Form>
    </Drawer>
  );

  return (
    <>
      <Card className="tw-overflow-hidden tw-h-full tw-bg-white tw-rounded-lg tw-border-neutral-200">
        <div className="tw-flex tw-justify-between tw-mb-4">
          <h1 className="tw-subhead tw-flex tw-items-center">
            <span>Gateways</span>

            <Popover content={gatewaysTooltip} overlayInnerStyle={popoverStyles}>
              <InfoCircleFilled className="tw-ml-2 tw-cursor-pointer" />
            </Popover>
          </h1>

          <div>
            <Button onClick={handleGatewayDrawer}>Add Gateway</Button>
          </div>
        </div>

        {currentDrawer}
        <DataTable
          columns={tableColumns}
          data={gateways}
          filters={tableFilters}
          onRowSelect={handleOnSelect}
          hasNextPage={hasNextPage}
          onLoadMore={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage || isFetching}
          disableGlobalFilter={true}
          // commented for future purposes
          // disableGlobalFilter={!filterableColumns.length}
          // onSearch={handleSearch}
        />
      </Card>
    </>
  );
};

export default GatewaysWidget;
