import { initialFiltersState } from './filter-constants';

export enum actionTypes {
  SELECT_FILTER = 'SELECT_FILTER',
  APPLY_FILTERS = 'APPLY_FILTERS',
  RESET_FILTERS = 'RESET_FILTERS',
}

export type actionTypesOptions = keyof typeof actionTypes;
type actionCreatorType = {
  type: actionTypesOptions;
  filterName: string;
  payload: string | null;
};

export function filtersReducer(state: typeof initialFiltersState, action: actionCreatorType) {
  switch (action.type) {
    case actionTypes.SELECT_FILTER: {
      return {
        ...state,
        [action.filterName]: action.payload,
      };
    }
    case actionTypes.APPLY_FILTERS: {
      return {
        ...state,
        [action.filterName]: action.payload,
      };
    }
    case actionTypes.RESET_FILTERS: {
      return {
        ...initialFiltersState,
      };
    }
    default:
      return state;
  }
}
