import React, { useCallback, useMemo, useState } from 'react';
import { flatten, map } from 'lodash';
import { InfoCircleFilled } from '@ant-design/icons';
import { Card, Drawer, Popover, Tooltip } from '@vgs/ui-core';
import { DataTable } from '@vgs/data-table';

import { useTransfersApi } from '@/hooks';
import { Transfer } from '@/api';
import { convertFromCents, formatDate, formatFieldToLabel, getUniqueCurrencyCodes } from '@/utils';
import { Loader } from '@/components';
import { columns, TransferStatus, TransferStatusCell } from './transfers-columns';

const popoverStyles = { maxWidth: 450 };

const TransfersWidget = () => {
  const [showTransferDrawer, setShowTransferDrawer] = useState<boolean>(false);
  const [selectedTransfer, setSelectedTransfer] = useState<Transfer | null>(null);
  const [filters, setFilters] = useState<{
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
  }>({ globalFilter: undefined, filters: [] });
  const { transfersList: transfersListWithSearch } = useTransfersApi();

  const tableColumns = useMemo(() => columns, []);
  const tableFilters = useMemo(() => filters, [filters]);

  const filterableColumns = useMemo(() => {
    return map(
      columns.filter(({ accessor, disableFilters }: any) => !!accessor && !disableFilters),
      'accessor',
    ) as string[];
  }, []);

  const transfersList = transfersListWithSearch({ ...filters, keys: filterableColumns });
  const { hasNextPage, isFetchingNextPage, isFetching, fetchNextPage } = transfersList;

  const transfers = useMemo(() => {
    const data = map(transfersList.data?.pages, 'data');

    return flatten(data);
  }, [transfersList.data]);

  const handleSearch = useCallback(
    (filters) => {
      setFilters({ ...filters, keys: filterableColumns });
    },
    [filterableColumns],
  );

  const handleDrawerClose = useCallback(() => {
    setShowTransferDrawer(false);
  }, []);

  const handleOnSelect = useCallback((row: Transfer) => {
    setSelectedTransfer(row);
    setShowTransferDrawer(true);
  }, []);

  const currencyCodes = useMemo(getUniqueCurrencyCodes, []);

  if (transfersList.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Card className="tw-overflow-hidden tw-h-full tw-bg-white tw-rounded-lg tw-border-neutral-200">
        <div className="tw-flex tw-justify-between tw-mb-4">
          <h1 className="tw-subhead tw-flex tw-items-center">
            <span>Transfers</span>
            <Popover
              content="Transfers represent payment transaction requests routed through a particular gateway."
              overlayInnerStyle={popoverStyles}
            >
              <InfoCircleFilled className="tw-ml-2 tw-cursor-pointer" />
            </Popover>
          </h1>
        </div>

        <DataTable
          columns={tableColumns}
          filters={tableFilters}
          data={transfers}
          onRowSelect={handleOnSelect}
          hasNextPage={hasNextPage}
          onLoadMore={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage || isFetching}
          disableGlobalFilter={true}
          onSearch={handleSearch}
        />
      </Card>

      <Drawer
        title="View Transfer"
        visible={showTransferDrawer}
        onClose={handleDrawerClose}
        width={560}
      >
        <div>
          <Card className="transfer-card">
            <h5 className="transfer-card-label tw-header">General</h5>
            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Id</div>

                <span className="tw-text-body-b2 tw-break-all">{selectedTransfer?.id}</span>
              </div>
            </section>
            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Created At</div>

                <Tooltip title={selectedTransfer?.createdAt}>
                  <span className="tw-text-body-b2 tw-break-all">
                    {formatDate(selectedTransfer?.createdAt)}
                  </span>
                </Tooltip>
              </div>

              <div className="tw-flex-1 tw-ml-6">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Updated At</div>

                <Tooltip title={selectedTransfer?.updatedAt}>
                  <span className="tw-text-body-b2 tw-break-all">
                    {formatDate(selectedTransfer?.updatedAt)}
                  </span>
                </Tooltip>
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div>
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Type</div>

                  <span className="tw-text-body-b2 tw-capitalize tw-break-all">
                    {selectedTransfer?.type}
                  </span>
                </div>
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Amount</div>

                <span className="tw-text-body-b2 tw-break-all">
                  {convertFromCents(selectedTransfer?.amount!)}
                </span>
              </div>

              <div className="tw-flex-1 tw-ml-6">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Currency</div>

                <span className="tw-text-body-b2 tw-break-all">
                  {selectedTransfer?.currency} - {currencyCodes[selectedTransfer?.currency!]}
                </span>
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div>
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Fee</div>

                  <span className="tw-text-body-b2 tw-capitalize tw-break-all">
                    {convertFromCents(selectedTransfer?.fee!)}
                  </span>
                </div>
              </div>

              <div className="tw-flex-1 tw-ml-6">
                <div>
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Amount Reversed</div>

                  <span className="tw-text-body-b2 tw-capitalize tw-break-all">
                    {convertFromCents(selectedTransfer?.amountReversed || 0)}
                  </span>
                </div>
              </div>
            </section>
          </Card>
          {selectedTransfer?.binAttributes && (
            <Card className="tw-mb-8">
              <h5 className="transfer-card-label tw-header">BIN Attributes</h5>
              <section className="tw-flex tw-mb-2">
                <div className="tw-flex-1">
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Issuer Name</div>

                  <span className="tw-text-body-b2 tw-break-all">
                    {selectedTransfer?.binAttributes?.issuerName}
                  </span>
                </div>

                <div className="tw-flex-1 tw-ml-6">
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Issuer Country</div>

                  <span className="tw-text-body-b2 tw-break-all">
                    {selectedTransfer?.binAttributes?.countryOfOrigin}
                  </span>
                </div>
              </section>

              <section className="tw-flex tw-mb-2">
                <div className="tw-flex-1">
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Card Type</div>

                  <span className="tw-text-body-b2 tw-capitalize tw-break-all">
                    {selectedTransfer?.type}
                  </span>
                </div>

                <div className="tw-flex-1  tw-ml-6">
                  <div>
                    <div className="tw-flex tw-items-center tw-text-body-bs2">Product Type</div>

                    <span className="tw-text-body-b2 tw-capitalize tw-break-all">
                      {selectedTransfer?.binAttributes?.productType}
                    </span>
                  </div>
                </div>
              </section>

              <section className="tw-flex tw-mb-2">
                <div className="tw-flex-1">
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Issuer Currency</div>

                  <span className="tw-text-body-b2 tw-break-all">{selectedTransfer?.currency}</span>
                </div>
              </section>
            </Card>
          )}

          <Card className="tw-mb-8">
            <h5 className="transfer-card-label tw-header">Gateway Details</h5>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Id</div>

                <span className="tw-text-body-b2 tw-break-all">
                  {selectedTransfer?.gateway?.id}
                </span>
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Type</div>

                <span className="tw-text-body-b2 tw-capitalize tw-break-all">
                  {selectedTransfer?.type}
                </span>
              </div>

              {selectedTransfer?.gateway?.defaultCurrency && (
                <div className="tw-flex-1">
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Default Currency</div>

                  <span className="tw-text-body-b2 tw-break-all">
                    {selectedTransfer?.gateway?.defaultCurrency} -{' '}
                    {currencyCodes[selectedTransfer?.gateway?.defaultCurrency!]}
                  </span>
                </div>
              )}
            </section>

            <section className="tw-flex">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Config</div>

                {Object.keys(selectedTransfer?.gateway?.config || {})?.map((field) => {
                  return (
                    <section key={field} className="tw-mb-4">
                      <div className="tw-flex tw-items-center tw-text-body-bs3 tw-mb-1 tw-capitalize">
                        {formatFieldToLabel(field)}
                      </div>

                      <span className="tw-text-body-b2 tw-capitalize tw-break-all">
                        {selectedTransfer?.gateway?.config[field]}
                      </span>
                    </section>
                  );
                })}
              </div>
            </section>
          </Card>

          <Card className="tw-mb-8">
            <h5 className="transfer-card-label tw-header">Gateway Response</h5>
            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Id</div>

                <span className="tw-text-body-b2 tw-break-all">
                  {selectedTransfer?.gatewayResponse?.id}
                </span>
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">State</div>

                {selectedTransfer?.gatewayResponse?.state && (
                  <div>
                    {' '}
                    <TransferStatusCell
                      value={selectedTransfer.gatewayResponse.state as TransferStatus}
                    />
                  </div>
                )}
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Message</div>

                <span className="tw-text-body-b2 tw-break-all">
                  {selectedTransfer?.gatewayResponse?.message}
                </span>
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1">
                <div>
                  <div className="tw-flex tw-items-center tw-text-body-bs2">Error Code</div>

                  <span className="tw-text-body-b2 tw-break-all">
                    {selectedTransfer?.gatewayResponse?.errorCode || 'N/A'}
                  </span>
                </div>
              </div>
            </section>

            <section className="tw-flex tw-mb-2">
              <div className="tw-flex-1 tw-overflow-auto">
                <div className="tw-flex tw-items-center tw-text-body-bs2">Raw Response</div>

                <pre className="tw-text-xs code-block tw-break-all">
                  {JSON.stringify(selectedTransfer?.gatewayResponse?.rawResponse, undefined, 2)}
                </pre>
              </div>
            </section>
          </Card>
        </div>
      </Drawer>
    </>
  );
};

export default TransfersWidget;
