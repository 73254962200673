import HttpClient from '@/services/HttpClient';
import config from '@vgs-config';
import { mapToCamelCase } from '../mappers';
import { currConfig, downloadConfig } from './request-configs';
import { getQueryString } from './utils';

class UsageReportingApi {
  static BASE_URL = config.api.usageReporting;

  get payoptStatsPath() {
    return '/payopt-stats/payments';
  }

  // TODO add error handling & notification
  // TODO add types for filtering
  async getUsageStats(
    { internalId, id, tenantId }: { internalId: string; id: string; tenantId: string },
    filters: any,
  ) {
    const queryString = getQueryString(filters);
    currConfig.headers['vgs-organization'] = internalId;
    const url = `${UsageReportingApi.BASE_URL}/organizations/${id}${this.payoptStatsPath}?vault=${tenantId}${queryString}`;
    const { data } = await HttpClient.get(url, currConfig);
    const usageStatsData = mapToCamelCase(data);
    return usageStatsData;
  }

  getCsvReport(internalId: string, id: string, tenantId: string, filters: any) {
    downloadConfig.headers['vgs-organization'] = internalId;
    const queryString = getQueryString(filters);
    const url = `${UsageReportingApi.BASE_URL}/organizations/${id}/csv-report/?vault=${tenantId}&report=FunctionRequestsCount${queryString}`;
    const usageStatsData = HttpClient.get(url, downloadConfig);
    return usageStatsData;
  }
}

export default new UsageReportingApi();
