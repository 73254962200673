import { FilterProvider } from './components/Filter/filter-context';
import UsageReports from './UsageReports';

export default function UsageReportsContainer() {
  return (
    <FilterProvider>
      <UsageReports />
    </FilterProvider>
  );
}
