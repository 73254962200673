import { useInfiniteQuery } from 'react-query';
import { MultiplexingApi, Response, Transfer } from '@/api';

export const useTransfersApi = () => {
  const fetchTransfers = ({ pageParam: page, queryKey }: { pageParam?: number; queryKey: any }) => {
    const [, { filter }] = queryKey;
    return MultiplexingApi.getTransfersList({ page, filter }) as Promise<Response<Transfer[]>>;
  };

  const useInfiniteQueryWithSearch = (filter?: {
    globalFilter?: string;
    filters: Array<{ id: string; value: string }>;
    keys: string[];
  }) => {
    return useInfiniteQuery(['transfers', { filter }], fetchTransfers, {
      getNextPageParam: ({ meta }: Response<Transfer[]>) => {
        const { nextPage } = meta || {};

        return nextPage;
      },
    });
  };

  return {
    transfersList: useInfiniteQueryWithSearch,
  };
};
