import {
  initialFiltersState,
  PaymentOrchestrationFilters,
} from '@/pages/reporting/components/Filter/filter-constants';
import { format, formatISO } from 'date-fns';
import { flow } from 'lodash';

type AvailableFilters = typeof initialFiltersState;

type SelectedFilters = [string, { name: string; value: string }];

type DateParams = {
  currentMonth: number;
  currentYear: number;
};

type FilterOption = {
  name: string;
  value: string;
};

export const buildDateSearchParams = (rawStartDate: Date, rawEndDate: Date) => {
  const start_date = format(rawStartDate, 'yyyy-MM-dd');
  const end_date = format(rawEndDate, 'yyyy-MM-dd');
  return { start_date, end_date };
};

export const getCurrentDateParams = () => {
  const todayDate = new Date();
  const currentMonth = todayDate.getMonth();
  const currentYear = todayDate.getFullYear();
  return { todayDate, currentMonth, currentYear };
};

export const transformDateParams = (value: FilterOption, dateParams: DateParams) => {
  const { currentMonth, currentYear } = dateParams;
  const previusMonth = currentMonth - 1;
  const previousYear = currentYear - 1;
  const transformedDateParams =
    value.value === 'this_month'
      ? { year: currentYear, month: previusMonth }
      : { year: previousYear, month: currentMonth };
  return transformedDateParams;
};

export const getDateSearchParams = (value: FilterOption) => {
  const { todayDate, currentMonth, currentYear } = getCurrentDateParams();
  const { year, month } = transformDateParams(value, { currentMonth, currentYear });
  const startDate = new Date(year, month, todayDate.getDate());
  return buildDateSearchParams(startDate, todayDate);
};

export const removeEmptyFilters = ({ filters }: { filters: AvailableFilters }) => {
  return Object.entries(filters).filter((item) => {
    if (item[1] !== null) return item;
    return;
  });
};

const removeTemporarySelectedFilterValues = (options: SelectedFilters[]) => {
  return options.map(([key, value]) => {
    if (!key.includes(`selected`)) return [key, value];
  });
};

export const formQuerySearchObject = (options: SelectedFilters[]) => {
  return Object.fromEntries(options.filter((option) => option));
};

export const transformFilters = flow([
  removeEmptyFilters,
  removeTemporarySelectedFilterValues,
  formQuerySearchObject,
]);

export const getPaymentMethodParams = (value: string) => ({ payment_method: value });
export const getPaymentRequestParams = (value: string) => ({ payment_request: value });

const callSpecificFilter = {
  timeFilter: getDateSearchParams,
  paymentMethodFilter: getPaymentMethodParams,
  paymentRequestFilter: getPaymentRequestParams,
};

export const buildSearchParams = (filters: PaymentOrchestrationFilters) => {
  const queryParamsArray = [];
  for (const [filterKey, param] of Object.entries(filters)) {
    //@ts-ignore
    const result = callSpecificFilter[`${filterKey}` as keyof typeof callSpecificFilter](param);
    queryParamsArray.push(result);
  }
  return queryParamsArray;
};

export const getQueryString = (rawFilters: { filters: AvailableFilters }) => {
  const transformedFilters = transformFilters(rawFilters);
  const searchParams = buildSearchParams(transformedFilters);
  let queryString = '';
  for (const [_, searchParameter] of searchParams.entries()) {
    if (typeof searchParameter === 'object') {
      const parameters = new URLSearchParams({ ...searchParameter });
      const joinSymbol = '&';
      queryString = queryString.concat(joinSymbol, parameters.toString());
    }
  }
  return queryString;
};
