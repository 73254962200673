import { ReactNode } from 'react';
import { RouteProps } from 'react-router-dom';
import { Guard } from '@vgs/hooks';
import { authGuard } from '@vgs/utils';
import {
  DashboardPage,
  GettingStartedPage,
  GatewaysPage,
  RulesPage,
  FinancialInstrumentsPage,
  TransfersPage,
  VaultSettingsPage,
  OrganizationSettingsPage,
  UserProfileSettingsPage,
  CheckoutPage,
  UsageReports,
} from '@/pages';
import { PATHS, multiplexingGuard, setupCompleteGuard } from '@/utils';
import UsageReportsContainer from './pages/reporting/UsageReportsContainer';

type RouteConfig = RouteProps & {
  canActivate?: Array<Guard>;
  loading?: ReactNode;
  redirectTo?: string;
  routes?: Array<RouteConfig>;
};

export const ROUTES: RouteConfig[] = [
  {
    path: PATHS.DASHBOARD,
    component: DashboardPage,
    canActivate: [authGuard, setupCompleteGuard],
    exact: true,
  },
  {
    path: PATHS.GETTING_STARTED,
    component: GettingStartedPage,
    canActivate: [authGuard, setupCompleteGuard],
    exact: true,
  },
  {
    path: PATHS.GATEWAYS,
    component: GatewaysPage,
    canActivate: [authGuard, setupCompleteGuard, multiplexingGuard],
    exact: true,
  },
  {
    path: PATHS.RULES,
    component: RulesPage,
    canActivate: [authGuard, setupCompleteGuard, multiplexingGuard],
    exact: true,
  },
  {
    path: PATHS.FINANCIAL_INSTRUMENTS,
    component: FinancialInstrumentsPage,
    canActivate: [authGuard, setupCompleteGuard, multiplexingGuard],
    exact: true,
  },
  {
    path: PATHS.TRANSFERS,
    component: TransfersPage,
    canActivate: [authGuard, setupCompleteGuard, multiplexingGuard],
    exact: true,
  },
  {
    path: PATHS.REPORTING,
    component: UsageReportsContainer,
    canActivate: [authGuard, setupCompleteGuard, multiplexingGuard],
    exact: true,
  },
  {
    path: PATHS.CHECKOUT,
    component: CheckoutPage,
    canActivate: [authGuard, setupCompleteGuard],
    exact: true,
  },
  {
    path: PATHS.SETTINGS.ROOT,
    canActivate: [authGuard, setupCompleteGuard],
    redirectTo: `${PATHS.SETTINGS.ROOT}${PATHS.SETTINGS.VAULT}`,
    routes: [
      {
        path: `${PATHS.SETTINGS.ROOT}${PATHS.SETTINGS.VAULT}`,
        component: VaultSettingsPage,
        canActivate: [authGuard, setupCompleteGuard],
        exact: true,
      },
      {
        path: `${PATHS.SETTINGS.ROOT}${PATHS.SETTINGS.ORGANIZATION}`,
        component: OrganizationSettingsPage,
        canActivate: [authGuard, setupCompleteGuard],
        exact: true,
      },
      {
        path: `${PATHS.SETTINGS.ROOT}${PATHS.SETTINGS.USER_PROFILE}`,
        component: UserProfileSettingsPage,
        canActivate: [authGuard, setupCompleteGuard],
        exact: true,
      },
    ],
  },
];
