import React, { useMemo } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { NavigationSidebar } from '@vgs/navigation-sidebar';
import { useAuth } from '@vgs/auth';

import { Header, GuardedRoute, Loader } from '@/components';
import { ROUTES } from '@/routes';
import { getNavLinks } from '@/utils';
import { useCurrentVault } from '@/hooks';
import './styles';
import './App.scss';
import { useFeature } from 'flagged';

function App() {
  const { email, initialized } = useAuth();
  const { isProvisioned } = useCurrentVault();
  const location = useLocation();
  const isProd = useFeature('isProd');

  const transformLinks = (
    navLinks: {
      label: string;
      links: {
        label: string;
        to: string;
      }[];
    }[],
  ) => {
    const [_, orchestrationLinks] = navLinks;
    const transformeOrchestrationLinks = orchestrationLinks.links.filter((item) =>
      isProd ? item.label !== 'Reporting' : item,
    );

    const transformedLinks = navLinks.map((item) => {
      if (item.label === 'Orchestration') {
        return { ...item, links: [...transformeOrchestrationLinks] };
      } else {
        return item;
      }
    });
    return transformedLinks;
  };

  const navLinks = useMemo(() => {
    const navLinks = getNavLinks(isProvisioned ?? false);
    return transformLinks(navLinks);
  }, [isProvisioned]);

  if (!initialized) {
    return (
      <div className="container">
        <Loader />
      </div>
    );
  }

  transformLinks(navLinks);
  return (
    <div className="tw-flex tw-h-screen tw-overflow-hidden">
      <Header user={email} sidebar />

      {location && <NavigationSidebar navLinks={navLinks} />}

      <main className={cx('tw-overflow-auto tw-flex-1 tw-pb-8 tw-pl-10 tw-pr-12 tw-pt-32')}>
        <Switch>
          {ROUTES.map((route) => (
            <GuardedRoute key={route.path as string} {...route} />
          ))}
        </Switch>
      </main>
    </div>
  );
}

export default App;
