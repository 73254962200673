import React, { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil } from 'lodash';
import { RightOutlined } from '@ant-design/icons';
import { Button, Card } from '@vgs/ui-core';

import config from '@vgs-config';
import { useCurrentVault } from '@/hooks';
import { PATHS } from '@/utils';
import { ReactComponent as ExternalLink } from '@vgs/assets/icons/link-out.svg';

const WelcomeWidget = () => {
  const { docsUrl } = config;
  const history = useHistory();
  const { isProvisioned } = useCurrentVault();

  const goToQuickStart = useCallback(() => {
    history.push(PATHS.GETTING_STARTED);
  }, [history]);

  return (
    <Fragment>
      <Card className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200 tw-mb-6">
        <div className="tw-flex tw-flex-col tw-h-full">
          <div className="tw-flex tw-flex-1 tw-p-8 tw-overflow-auto tw-justify-between sm:tw-flex-col sm:tw-flex-col-reverse md:tw-flex-row">
            <div className="widget-content">
              <h2 className="tw-header">
                Welcome to Payment Optimization from Very Good Security!
              </h2>

              <div className="tw-text-body-b2 tw-mb-4">
                VGS Payment Optimization Solution enables you to craft your payments approach to
                accomplish the following:
                <ul className="tw-list-disc tw-mb-4 tw-ml-4">
                  <li>Expand both acceptance of payment methods and geographical support</li>
                  <li>
                    Maximize your sales throughput by mitigating false positive declines resulting
                    in higher approval rates
                  </li>
                  <li>
                    Optimize the cost of routing payments based on your custom logic derived from
                    your payments strategy
                  </li>
                </ul>
                <a
                  className="tw-flex tw-items-center tw-p-0 tw-text-primary hover:tw-no-underline"
                  href={docsUrl.paymentOptimization}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="tw-mr-2">Learn more</span>
                  <ExternalLink />
                </a>
              </div>
            </div>

            <div className="tw-flex tw-items-start tw-ml-12 sm:tw-justify-center sm:tw-m-4">
              <img src="/images/vgs-payments-overview.svg" alt="VGS" />
            </div>
          </div>
        </div>
      </Card>

      {!isNil(isProvisioned) && !isProvisioned && (
        <Card className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200">
          <div className="tw-flex tw-flex-col tw-h-full">
            <div className="tw-flex tw-flex-1 tw-p-8 tw-overflow-auto tw-justify-between sm:tw-flex-col sm:tw-flex-col-reverse md:tw-flex-row">
              <div>
                <h2 className="tw-header">Get VGS Payment Orchestration</h2>

                <p className="tw-text-body-b2 tw-mb-4">
                  The VGS Payment Orchestration facilitates logic based payments routing to 180+
                  gateways. It enables payment facilitators and merchants to build logic route their
                  payments based on any attribute to accomplish their desired business result.
                </p>

                <div className="tw-flex">
                  <Button className="tw-flex tw-align-center tw-mr-4" onClick={goToQuickStart}>
                    Get Started <RightOutlined />
                  </Button>

                  <a
                    className="tw-flex tw-items-center tw-p-0 tw-text-primary hover:tw-no-underline"
                    href={docsUrl.paymentOrchestration}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="tw-mr-2">Learn more</span>
                    <ExternalLink />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Fragment>
  );
};

export default WelcomeWidget;
