import Rollbar from 'rollbar';
import config from '@vgs-config';

let rollbarInstance: any;

function isRollbarEnabled() {
  return config.rollbar.enabled && window.location.hostname !== 'localhost';
}

export const initRollbar = () => {
  if (isRollbarEnabled()) {
    rollbarInstance = new Rollbar({
      accessToken: config.rollbar.accessToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: config.env,
        client: {
          javascript: {
            source_map_enabled: true,
            guess_uncaught_frames: true,
          },
        },
      },
      autoInstrument: true,
      scrubTelemetryInputs: true,
    });

    window.rollbarInstance = rollbarInstance;
  }
};

export const configureRollbar = (rollbarConfig: object) => {
  if (isRollbarEnabled() && rollbarInstance) {
    rollbarInstance.configure({
      payload: rollbarConfig,
    });
  }
};

export const sendErrorToRollbar = (e: unknown) => {
  if (isRollbarEnabled()) {
    rollbarInstance.error(e, { sentManually: true });
  }
};

export const sendCriticalToRollbar = (e: unknown) => {
  if (isRollbarEnabled()) {
    rollbarInstance.critical(e, { sentManually: true });
  }
};

export const setTenantId = (tenantIdentifier: string) => {
  configureRollbar({
    custom: {
      tenantIdentifier,
    },
  });
};
