import React, { useCallback } from 'react';
import cx from 'classnames';
import config from '@vgs-config';
import { Button, Dropdown, Menu, Popover } from '@vgs/ui-core';
import { useAuth } from '@vgs/auth';

import { VaultSwitcher } from '../vault-switcher';
import { OrganizationSwitcher } from '@/components';
import cn from 'classnames';
import { ApplicationSwitcher } from '@vgs/application-switcher';
import { noop } from 'lodash';
import { NavLink } from 'react-router-dom';

import { ReactComponent as UserProfileIcon } from '@vgs/assets/icons/user-profile.svg';
import { ReactComponent as DocsIcon } from '@vgs/assets/icons/docs.svg';
import { ReactComponent as SupportIcon } from '@vgs/assets/icons/support.svg';
import { AvailableService } from '@vgs/application-switcher/lib/available-services';

const applicationSwitcherUrls = { ...config.servicesUrl, payments: '/' };

const Header: React.FC<{ sidebar?: boolean; user: string }> = (props) => {
  const { sidebar } = props;
  const { docsUrl, support } = config;
  const { logout } = useAuth();

  const handleLogout = useCallback(() => logout(), [logout]);

  const supportInfo = (
    <div className="tw-text-body-b3">
      Email: <a href={`mailto:${support.email}`}>{support.email}</a>
      <br />
      Online:{' '}
      <a href={support.url} target="_blank" rel="noopener noreferrer">
        {support.url}
      </a>
    </div>
  );

  return (
    <header className={cx('header navbar-container')}>
      <div className="tw-flex switch-bar tw-h-full tw-items-center tw-justify-between tw-px-12">
        <div
          className={cx('tw-w-1/6', {
            'tw-hidden': sidebar,
          })}
        ></div>

        <div className="tw-flex switcher-container">
          <OrganizationSwitcher className="tw-mr-4" />
          <VaultSwitcher />
        </div>

        <nav className="tw-flex tw-h-full">
          <ul className="tw-flex tw-items-center tw-m-0">
            <li className="tw-flex tw-h-full tw-items-center">
              <a
                href={docsUrl.overview}
                className="tw-px-4 tw-flex  tw-items-center tw-no-underline hover:tw-text-current hover:tw-no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DocsIcon />
                <span className="tw-ml-2">Docs</span>
              </a>
            </li>
            <li className="tw-flex tw-h-full tw-fl3ex-row tw-items-center">
              <Popover
                className="tw-px-4 tw-items-center tw-flex tw-pl-4"
                content={supportInfo}
                trigger="click"
                placement="bottomLeft"
              >
                <SupportIcon />
                <span className="tw-ml-2 tw-items-center tw-cursor-pointer">Support</span>
              </Popover>
            </li>
            <li className="user-profile">
              <Dropdown
                overlay={
                  <Menu className="tw-w-40 tw-py-2">
                    <NavLink className="dropdown-item" to="/settings/user-profile">
                      User Profile
                    </NavLink>
                    <Menu.Item className="dropdown-item" key="logout" onClick={handleLogout}>
                      <span>Logout</span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <Button type="link">
                  <UserProfileIcon />
                </Button>
              </Dropdown>
            </li>
          </ul>
        </nav>
      </div>
      <ApplicationSwitcher
        target="_blank"
        menuProps={{
          theme: 'dark',
          mode: 'horizontal',
          className: 'application-switcher',
        }}
        itemProps={(service: AvailableService | undefined) => {
          const isPaymentsTab = service === 'payments';
          const props: Record<string, unknown> = {
            className: cn('tw-text-primary-300 hover:tw-text-white  tw-text-base', {
              'active-menu-item': isPaymentsTab,
              'hover:tw-text-white hover:tw-bg-primary-800': !isPaymentsTab,
            }),
          };
          if (service === 'payments') {
            props.onClick = noop;
            props.children = (
              <NavLink className="tw-no-underline" to={'/'} exact>
                <span className="tw-subhead tw-leading-normal tw-text-white tw-text-base tw-font-inter">
                  Payment Optimization
                </span>
              </NavLink>
            );
          }
          return props;
        }}
        urls={applicationSwitcherUrls}
      ></ApplicationSwitcher>
    </header>
  );
};

export default Header;
