import React, { FC, useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { Input } from '@vgs/ui-core';

import { ReactComponent as SearchIcon } from '@vgs/assets/icons/search.svg';

interface SearchInputProps {
  debounce?: number;
  placeholder?: string;
  onSearch: (value: string) => void;
  value?: string;
}

const DEFAULT_PLACEHOLDER = 'Search';
const DEFAULT_TIMEOUT = 450;

const SearchInput: FC<SearchInputProps> = (props) => {
  const {
    debounce: debounceTimeout = DEFAULT_TIMEOUT,
    placeholder = DEFAULT_PLACEHOLDER,
    value = '',
  } = props;
  const [query, setQuery] = useState<string>(value);

  const onSearch = useCallback(
    debounce((value: string) => props.onSearch(value), debounceTimeout),
    [],
  );

  const handleSearch = useCallback((e) => {
    const { value } = e.target;

    setQuery(value);
    onSearch(value.trim());
  }, []);

  return (
    <Input
      placeholder={placeholder}
      suffix={<SearchIcon />}
      value={query}
      onChange={handleSearch}
    />
  );
};

export default SearchInput;
