import React, { Fragment, useCallback } from 'react';
import { Button, Card } from '@vgs/ui-core';
import config from '@vgs-config';

import { ReactComponent as ExternalLink } from '@vgs/assets/icons/link-out.svg';
import { useCurrentVault } from '@/hooks';

const GettingStartedWidget = () => {
  const {
    docsUrl,
    support: { email: supportEmail },
  } = config;
  const { isProvisioned } = useCurrentVault();

  const supportUrl = `mailto:${supportEmail}`;

  const handleRequestAccess = useCallback(() => {
    window.location.assign(supportUrl);
  }, [supportUrl]);

  return (
    <Fragment>
      {isProvisioned ? (
        <Card className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200">
          <div className="tw-flex tw-flex-col tw-h-full">
            <div className="tw-flex tw-flex-1 tw-p-8 tw-overflow-auto tw-justify-between sm:tw-flex-col sm:tw-flex-col-reverse md:tw-flex-row">
              <div>
                <h2 className="tw-header">Getting Started with Payment Orchestration</h2>

                <div className="tw-text-body-b2 tw-mb-4">
                  <p className="tw-mb-6">
                    VGS Payment Orchestration offers an API to facilitate the routing of your
                    payment transactions to 180+ gateways.
                  </p>
                  <p className="tw-mb-6">
                    Your Payment Orchestration API personal instance is hosted by VGS. Please
                    contact <a href="mailto:support@verygoodsecurity.com">VGS Support</a> to get
                    your URL.
                  </p>

                  <a
                    className="tw-flex tw-items-center tw-p-0 tw-text-primary hover:tw-no-underline"
                    href={docsUrl.paymentOrchestration}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="tw-mr-2">Learn more</span>
                    <ExternalLink />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200">
          <div className="tw-flex tw-flex-col tw-h-full">
            <div className="tw-flex tw-flex-1 tw-p-8 tw-overflow-auto tw-justify-between sm:tw-flex-col sm:tw-flex-col-reverse md:tw-flex-row">
              <div>
                <h2 className="tw-header">VGS Payment Orchestration</h2>

                <div className="tw-text-body-b2 tw-mb-4">
                  <p className="tw-mb-6">
                    VGS Payment Orchestration offers an API to facilitate the routing of your
                    payment transactions to 180+ gateways.
                  </p>
                  <p className="tw-mb-6">
                    We provide you with a solution hosted by VGS, which saves your time on
                    integration.
                  </p>
                  <p className="tw-mb-6">
                    Request access to get your Payment Orchestration instance.
                  </p>

                  <div className="tw-flex">
                    <Button
                      className="tw-flex tw-align-center tw-mr-4"
                      onClick={handleRequestAccess}
                    >
                      Request Access
                    </Button>

                    <a
                      className="tw-flex tw-items-center tw-p-0 tw-text-primary hover:tw-no-underline"
                      href={docsUrl.paymentOrchestration}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="tw-mr-2">Read Docs</span>
                      <ExternalLink />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Fragment>
  );
};

export default GettingStartedWidget;
