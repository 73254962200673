import { transformReportingPayload } from '@/pages/reporting/utils';
import { useState, useEffect } from 'react';
import { UsageMetricsData } from './useUsageReportingApi';

function useTransformedChartData(usageStatData: any) {
  const [transformedChartData, setTransformedChartData] = useState<UsageMetricsData[]>([]);

  useEffect(() => {
    if (usageStatData?.metrics) {
      const transformedUsageData = usageStatData?.metrics.map((item: UsageMetricsData) =>
        transformReportingPayload(item),
      );
      setTransformedChartData(transformedUsageData);
    }
  }, [usageStatData?.metrics]);

  return { transformedChartData };
}

export default useTransformedChartData;
