import React, { Fragment } from 'react';
import { Card, Tabs } from '@vgs/ui-core';
import { ReactComponent as ExternalLink } from '@vgs/assets/icons/link-out.svg';
import { checkoutConfig } from './checkout-config';
const { TabPane } = Tabs;

const CheckoutWidget = () => {
  const checkoutCardTemplate = (key: string) => {
    return (
      <div>
        <h5 className="tw-header tw-mt-4 tw-mb-6">{checkoutConfig[key].title}</h5>
        <Card className="widget tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border-neutral-200">
          <div className="tw-flex tw-flex-col tw-h-full">
            <div className="tw-flex tw-flex-1 tw-p-6 tw-overflow-auto tw-justify-between sm:tw-flex-col sm:tw-flex-col-reverse md:tw-flex-row">
              <div>
                <div className="tw-text-body-b2">
                  <p>
                    <a
                      className="tw-text-primary hover:tw-no-underline"
                      href={checkoutConfig[key].link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{checkoutConfig[key].title} </span>
                    </a>
                    {checkoutConfig[key].description}
                  </p>
                  <h5 className="tw-header tw-mt-8 tw-mb-6">Getting Started Guide:</h5>
                  <ol className="tw-list-decimal tw-mb-4 tw-ml-4">
                    {checkoutConfig[key].links.map((item: { [key: string]: string }, index) => (
                      <li key={index} className="tw-mb-4">
                        <a
                          className="tw-flex tw-items-center tw-p-0 tw-text-primary hover:tw-no-underline"
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="tw-mr-2">{item.label}</span>
                          <ExternalLink />
                        </a>
                      </li>
                    ))}
                  </ol>
                  <Card className="tw-overflow-hidden app-demo-card tw-bg-white tw-rounded-lg tw-mt-10 tw-border-neutral-200">
                    <div className="tw-flex tw-flex-col tw-h-full">
                      <div className="tw-flex tw-flex-1 tw-p-4 tw-overflow-auto tw-justify-between sm:tw-flex-col sm:tw-flex-col-reverse md:tw-flex-row">
                        <div>
                          <h1 className="tw-subhead">Demo Application</h1>
                          <p className="tw-text-sm tw-mb-10">
                            {checkoutConfig[key].demoAppDescription}
                          </p>
                          <a
                            className="tw-flex tw-items-center tw-p-0 tw-text-primary hover:tw-no-underline"
                            href={checkoutConfig[key].demoAppLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="tw-mr-2">Read more</span>
                            <ExternalLink />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <Fragment>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Web" key="1">
          {checkoutCardTemplate('web')}
        </TabPane>
        <TabPane tab="iOS" key="2">
          {checkoutCardTemplate('iOS')}
        </TabPane>
        <TabPane tab="Android" key="3">
          {checkoutCardTemplate('android')}
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

export default CheckoutWidget;
