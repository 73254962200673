import React, { PropsWithChildren } from 'react';
import { intersection } from 'lodash';

export enum Access {
  ADMIN = 'admin',
  READ = 'read',
  WRITE = 'write',
}

interface PermissionProps {
  config: Access[];
  fallback?: React.ReactNode;
  permissions: Access[];
}

const Permission = (props: PropsWithChildren<PermissionProps>) => {
  const { config, children, permissions, fallback } = props;

  const hasPermissions = intersection(config, permissions ?? [])?.length;

  if (!hasPermissions) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};

Permission.Admin = (props: PropsWithChildren<Omit<PermissionProps, 'permissions'>>) => (
  <Permission {...props} permissions={[Access.ADMIN]} />
);

Permission.Read = (props: PropsWithChildren<Omit<PermissionProps, 'permissions'>>) => (
  <Permission {...props} permissions={[Access.READ]} />
);

Permission.Write = (props: PropsWithChildren<Omit<PermissionProps, 'permissions'>>) => (
  <Permission {...props} permissions={[Access.WRITE]} />
);

export default Permission;
