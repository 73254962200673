import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { head } from 'lodash';
import { useAccountsApi, usePersonalizationApi } from '@/hooks';
import { MultiplexingApi, Organization, Vault } from '@/api';
import { useAuth } from '@vgs/auth';

const DEFAULT_ORGANIZATION = null;
const DEFAULT_VAULT = null;

interface CurrentVaultProps {
  organization?: Organization | null;
  switchOrganization: (organization: Organization) => void;
  vault?: Vault | null;
  linkedVaults: Vault[];
  switchVault: (vault: Vault) => void;
  isProvisioned?: boolean;
  isForbidden?: boolean;
}

const CurrentVaultContext = createContext<CurrentVaultProps>({
  organization: DEFAULT_ORGANIZATION,
  vault: DEFAULT_VAULT,
  linkedVaults: [],
  switchVault: () => {},
  switchOrganization: () => {},
});

export const useCurrentVault = () => {
  return useContext(CurrentVaultContext);
};

export const useCurrentVaultStatus = (id: string, options?: any) => {
  return useQuery(['status', id], () => MultiplexingApi.getInstanceStatus(id), options);
};

export const CurrentVaultProvider: React.FC<{}> = (props) => {
  const { children } = props;
  const { sub, initialized } = useAuth();

  const [organization, setOrganization] = useState<Organization | null>();
  const [vault, setVault] = useState<Vault | null>();

  const { userProfile, updateUserProfile } = usePersonalizationApi(sub!, { enabled: !!sub });
  const { organizations, vaults } = useAccountsApi({
    enabled: !!initialized,
  });

  const { data: status } = useCurrentVaultStatus(vault?.identifier || '', {
    enabled: !!vault,
  });

  useEffect(() => {
    const currentVault =
      vaults.data?.find(({ id }) => id === (userProfile.data as any)?.settings?.currentVault) ||
      (head(vaults.data) as Vault);

    if (currentVault) {
      MultiplexingApi.setBaseUrl(currentVault.identifier, currentVault.environment);
      setVault(currentVault);
    }
  }, [vaults.data, userProfile.data]);

  useEffect(() => {
    if (vault) {
      const currentOrganization = organizations.data?.find(({ id }) => id === vault.organizationId);

      setOrganization(currentOrganization);
    }
  }, [vault, organizations.data]);

  const linkedVaults = useMemo(() => {
    return vaults.data?.filter(({ organizationId }) => organizationId === organization?.id) || [];
  }, [organization?.id, vaults.data]);

  const switchVault = useCallback(
    (vault: Vault) => {
      const { identifier, environment, id } = vault;

      MultiplexingApi.setBaseUrl(identifier, environment);
      setVault(vault);

      const settings = {
        currentVault: id!,
      };

      updateUserProfile.mutate({ userId: sub!, settings });
    },
    [sub, updateUserProfile],
  );

  const switchOrganization = useCallback(
    (org: Organization) => {
      const nextVault =
        vaults.data?.find(({ organizationId }) => organizationId === org?.id) ||
        ({ id: '' } as Vault);

      const settings = {
        currentVault: nextVault.id as string,
      };

      MultiplexingApi.setBaseUrl(nextVault.identifier, nextVault.environment);

      setOrganization(org);
      setVault(nextVault);

      updateUserProfile.mutate({ userId: sub!, settings });
    },
    [sub, vaults.data, updateUserProfile],
  );

  const config = {
    organization,
    switchOrganization,
    vault,
    linkedVaults,
    switchVault,
    isProvisioned: status?.isProvisioned && !status?.isForbidden,
  };

  return <CurrentVaultContext.Provider value={config}>{children}</CurrentVaultContext.Provider>;
};
