import { useQuery } from 'react-query';
import { UsageReportingApi } from '@/api';
import FileSaver from 'file-saver';
const QUERY_STALE_TIME = 3 * 1000;

export type UsageMetricsData = {
  pending: number;
  approved: number;
  declined: number;
  date: string;
  totalSubmitted?: number;
};

export type PayOrchestrationUsageReports = {
  metrics: UsageMetricsData[];
  total_approved: number;
  total_blocked: number;
  total_declined: number;
  total_pending: number;
  total_submitted: number;
  total_volume: number;
};
type UsageStatsPayload = PayOrchestrationUsageReports;

const saveFile = async (internalId: string, id: string, tenantId: string, filters: string) => {
  const response = await UsageReportingApi.getCsvReport(internalId, id, tenantId, filters);
  const filename = 'usage-reports.csv';
  const file = new File([response.data], filename, { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(file);
};

export const useUsageReporting = ({ internalId, id, tenantId }: any, filters?: any) => {
  const usageStats = useQuery<UsageStatsPayload>(
    ['usageReports', id, internalId],
    async () => await UsageReportingApi.getUsageStats({ internalId, id, tenantId }, filters),
    {
      staleTime: QUERY_STALE_TIME,
    },
  );

  const useCsvReports = useQuery(
    ['csvReport', id, internalId],
    async () => await saveFile(internalId, id, tenantId, filters),
    {
      staleTime: QUERY_STALE_TIME,
      enabled: false,
    },
  );
  return { usageStats, useCsvReports };
};
