import React, { FC, useCallback, useState } from 'react';
import { get } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip } from '@vgs/ui-core';
import { filterByDateRangeOnClient, DateRangePicker } from '@vgs/data-table';
import { formatDate } from '@/utils';
import { DeletePrompt } from '@/components';
import { useGatewaysApi } from '@/hooks';

export const formatGatewayType = (gatewayOptionsList: any, value?: string) =>
  value && get(gatewayOptionsList, [value, 'name']);

const GatewayTypeCell: FC<{ value: string }> = React.memo(({ value }) => {
  const { gatewayOptionsList } = useGatewaysApi();
  const type = formatGatewayType(gatewayOptionsList.data, value);

  return <span>{type}</span>;
});

const GatewayDateCell: FC<{ value: string }> = React.memo((props) => {
  const { value } = props;

  return (
    <Tooltip title={value}>
      <span>{formatDate(value)}</span>
    </Tooltip>
  );
});

const GatewayActionCell = React.memo((props) => {
  const {
    row: {
      original: { id },
    },
  } = props as any;
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const { deleteGateway } = useGatewaysApi();

  const togglePrompt = useCallback(
    (isVisible = true) =>
      (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setShowDeletePrompt(isVisible);
      },
    [],
  );

  const handleOnDelete = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      deleteGateway.mutate(id, {
        onSuccess: () => setShowDeletePrompt(false),
        onError: () => setShowDeletePrompt(false),
      });
    },
    [id, deleteGateway],
  );

  return (
    <>
      <Button type="error" size="sm" className="tw-flex tw-align-center" onClick={togglePrompt()}>
        <CloseOutlined />
        Delete
      </Button>
      <DeletePrompt
        visible={showDeletePrompt}
        isLoading={deleteGateway.isLoading}
        title="Confirm gateway removal"
        onDelete={handleOnDelete}
        onCancel={togglePrompt(false)}
      >
        Are you sure delete this gateway?
      </DeletePrompt>
    </>
  );
});

export const columns = [
  {
    Header: 'Id',
    accessor: 'id',
    minWidth: 300,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: GatewayTypeCell,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: GatewayDateCell,
    minWidth: 200,
    filter: filterByDateRangeOnClient,
    Filter: DateRangePicker,
  },
  {
    Header: 'Action',
    id: 'action',
    Cell: GatewayActionCell,
    width: 150,
  },
];
